import React, { memo } from 'react';
import { StyleSheet, View, FlatList } from 'react-native';

import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import CustomerReview from './CustomerReview';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#979BAA',
  },
  footer: {
    height: 1,
    backgroundColor: colors.background,
    marginHorizontal: 16,
    width: Utility.getScreenWidth() - 32,
    alignSelf: 'center',
    marginBottom: 16,
  },
  allReviewsFooter: {
    height: 0,
    backgroundColor: colors.background,
    marginHorizontal: 16,
    width: Utility.getScreenWidth() - 16,
    alignSelf: 'center',
    marginTop: 0,
  },
  container: {
    flex: 1,
    backgroundColor: colors.white,
    marginBottom: 0,
    // paddingHorizontal: 16,
    paddingVertical: 0,
    paddingTop: 16,
  },
  allReviewsContainer: {
    backgroundColor: colors.white,
    marginBottom: 4,
    paddingHorizontal: 16,
    paddingVertical: 16,
    paddingTop: 16,
  },
  reviewImage: {
    width: 64,
    height: 64,
    marginRight: 16,
    borderRadius: 4,
    resizeMode: 'contain',
  },
  timestamp: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#979BAA',
  },
  verticalTextSeparator: {
    height: 13,
    width: 1,
    backgroundColor: '#979BAA',
    marginHorizontal: 4,
  },
  verifiedPurchaseText: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#979BAA',
  },
  verifiedPurchaseImage: { width: 12, height: 12, marginRight: 4 },
  verifiedPurchaseContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verifiedPurchaseAndTimeStamp: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  likeRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  liked: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    marginLeft: 12,
    color: '#4285F4',
  },
  unLiked: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    marginLeft: 12,
    color: colors.subtitle,
  },
  likedImage: {
    width: 18,
    height: 16,
    tintColor: '#4285F4',
  },
  unLikedImage: {
    width: 18,
    height: 16,
  },
  likeButtonContainer: { flexDirection: 'row' },
  userName: {
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#979BAA',
    marginBottom: 6,
    marginTop: 4,
  },
  readMore: {
    lineHeight: 21,
    marginTop: 4,
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#4285F4',
  },
  body: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    opacity: 0.8,
    lineHeight: 21,
  },
  bodyContainer: { marginVertical: 12 },
  headingContainer: { flexDirection: 'row' },
  ratingContainer: { height: 18 },
  heading: {
    fontSize: 14,
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    marginLeft: 12,
  },
});

const createEntityForCustomerReview = (reviewData, item) => {
  const customerReviewData =
    reviewData.filter((review) => review.id === item.id)?.[0] || {};
  return {
    name: customerReviewData.user_name || '',
    image: customerReviewData.user_image_url || '',
    subject: customerReviewData.subject || '',
    rating: customerReviewData.star || '',
  };
};

const ProductReviewItems = ({
  reviewData,
  onEndReached,
  productId,
  productSlug,
  allReviews = false,
  listFooterComponent,
  listHeaderComponent,
  variants,
  hideLastItemFooter = false,
  previousScreen = '',
}) => {
  if (Utility.isBlank(reviewData)) {
    return null;
  }

  const renderItems = ({ item, index }) => {
    const shouldHideFooter =
      hideLastItemFooter && index === reviewData.length - 1;
    const entity = createEntityForCustomerReview(reviewData, item);
    return (
      <CustomerReview
        item={item}
        variants={variants}
        productId={productId}
        allReviews={allReviews}
        productSlug={productSlug}
        entity={entity}
        hideFooter={shouldHideFooter}
        previousScreen={previousScreen}
      />
    );
  };

  const parentKeyExtractor = (_, index) => index.toString();

  const containerStyle =
    previousScreen === SCREEN_CONSTANTS.INFLUENCER ? [styles.container, { paddingTop: 0 }] : styles.container;

  return (
    <View style={containerStyle}>
      <FlatList
        data={reviewData}
        keyExtractor={parentKeyExtractor}
        showsVerticalScrollIndicator={false}
        renderItem={renderItems}
        onEndReached={onEndReached}
        ListFooterComponent={listFooterComponent}
        ListHeaderComponent={listHeaderComponent}
        scrollEnabled
      />
    </View>
  );
};

export default memo(ProductReviewItems);
