import { StyleSheet, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';
import RoundedButton from '../layout/buttons/RoundedButton';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import images from '../../theme/Images';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { AnalyticsManager } from '../../analytics';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

export default function Alert(props) {
  const {
    item: { alert: alertItem = {} },
    previousScreen,
    itemData = {},
    fullWidth,
  } = props;
  const navigation = useNavigation();

  const [alert, setAlert] = useState(itemData);

  useEffect(() => {
    try {
      new JSONAPIDeserializer({
        typeAsAttribute: false,
        pluralizeType: true,
        keyForAttribute: 'camelCase',
      })
        .deserialize(alertItem)
        .then((data = {}) => {
          setAlert(data);
          const { trackingData } = data;
          AnalyticsManager.logEvent('alert_view', {
            previousScreen,
            id: data.id,
            ...trackingData,
          });
        });
    } catch (e) {}
  }, []);

  const {
    title = '',
    subtitle = '',
    imageUrl = '',
    ctaText = '',
    ctaUrl = '',
    ctaColor = '',
  } = alert || {};

  if (isBlank(title)) {
    return null;
  }

  const image = isBlank(imageUrl) ? images.alert_message : { uri: imageUrl };

  const navigateToScreen = ({ route, slug, path, extra = {}, params, url }) => {
    AnalyticsManager.logEvent('item_action', {
      previousScreen,
      type: 'alert',
      route,
      ...params,
    });
    navigation.navigate(route, { slug, extra, params, url });
  };

  const onButtonClick = () => {
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      ctaUrl,
      navigateToScreen,
    );
  };

  return (
    <DebouncedTouchableOpacity
      onPress={onButtonClick}
      style={fullWidth ? styles.fullWidthContainer : styles.container}
    >
      <View style={styles.innerContainer}>
        <FastImageView
          source={getMinifiedImage(image.uri)}
          style={styles.imageStyle}
        />
        <View style={styles.textContainer}>
          <Text style={styles.title} numberOfLines={1}>
            {title}
          </Text>
          <Text numberOfLines={2} style={styles.subtitle}>
            {subtitle}
          </Text>
        </View>
      </View>

      {isPresent(ctaUrl) && (
        <RoundedButton
          buttonText={ctaText}
          buttonTextColor={colors.foxyBlack}
          buttonColor={ctaColor || colors.foxyBlack}
          buttonAction={onButtonClick}
          style={styles.buttonContainer}
        />
      )}
    </DebouncedTouchableOpacity>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  subtitle: {
    fontSize: 12,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginHorizontal: 12,
    backgroundColor: colors.white,
    paddingVertical: 12,
    paddingHorizontal: 12,
    margin: 1,
    borderRadius: 4,
  },
  fullWidthContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    paddingVertical: 12,
    paddingHorizontal: 12,
    margin: 1,
  },
  imageStyle: {
    height: 38,
    width: 38,
    marginRight: 12,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  buttonContainer: { height: 22, width: 82 },
  textContainer: {
    flex: 1,
    marginRight: 4,
  },
});
