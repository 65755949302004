import React, { Component } from 'react';
import {
  Platform,
  NativeEventEmitter,
  NativeModules,
  AppState,
  Linking,
  DeviceEventEmitter,
} from 'react-native';
import Keychain from '../libraries/ReactNativeKeychain';
import { fromBottom, fromRight, fadeIn } from 'react-navigation-transitions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v7 as uuidv7 } from 'uuid';
import PropTypes from 'prop-types';
import _, { isNull } from 'lodash';
import Onboarding from '../containers/onboarding/Onboarding';

import Utility from '../utils/Utility';
import AudioHandler from '../utils/AudioHandler';
import { publishPost, uploadScannedImage } from '../actions/UploadPostActions';
import {
  ANDROID,
  SELFIE_STATE,
  APP_LOAD_SOURCE,
  REMOTE_CONFIG_KEYS,
  LINK_DOMAIN_SHORT,
  CURRENT_GUEST_STATE,
  APP_LAUNCH_STATES,
  PERMISSION_CONSTANTS,
  CART_NOTIFICATION_ADD_ADDRESS_URL,
  CART_NOTIFICATION_MAKE_PAYMENT_URL,
  CART_NOTIFICATION_PAYMENT_DONE_URL,
  EnableMandatoryLogin,
  SyncOrdersOnAppOpen,
} from '../config/Constants';

import {
  storeCurrentOtp,
  verifyOtp,
  isRequestedFromHintPrompt,
  appLastOpenAt,
  setPhoneNumberModalInfo,
  retrySelfieAnalysis,
  resumeSelfieFromUserAttributeFetching,
  resumeSelfieFromUserAttributesMapping,
  setAppKillReason,
  setAppInstallSource,
  saveUserState,
  showUploadTrayOnFeed,
  abortMediaUpload,
  saveGuestAuthToken,
  registerGuestUser,
  saveAuthTokenToDevice,
} from '../actions/LoginActions';

import {
  refreshMyAttributes,
} from '../actions/FacialAnalysisActions';

import {
  setVideoUploadProgress,
  getListDetails,
  applyCartCoupon,
  uacDeviceEventTriggered,
  uacCommerceAppEventTriggered,
  uacBeautyAppEventTriggered,
  saveRefferalCode,
  postDeviceInformation,
  setVideoUploadStatus,
  saveBagModalOpenTimestampAndAppLaunchState,
  updateLocalNotificationData,
  setAppFirstOpened,
  getMyProfileDetails,
  userRegisteredOnCt,
  syncRemoteOrders,
  refreshMyProfile,
} from '../actions/ActionTypes';
import { updateMediaId } from '../actions/MediaDetailActions';

import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
  AnalyticsUtilty,
} from '../analytics';
import NotificationManager from '../utils/NotificationsManager';
import DynamicLinkManager from '../utils/DynamicLinkManager';
import NavigationService from './NavigationService';
import RemoteConfig from '../utils/RemoteConfig';
// import { createSharedElementStackNavigator } from 'react-navigation-shared-element';
import Logger from '../utils/Logger';
import { AppStack, updateSelfieUrl } from './NavigationStacks';
import { FREE_GIFT_DATA } from '../containers/inviteCenter/Constants';
import NotificationSoundsManager from '../utils/NotificationSoundsManager';
import Config from '../libraries/ReactNativeConfig';
import NotificationsHandler from '../utils/Notification/NotificationsHandler';
import {
  initialize,
  setCustomUserId,
} from '../libraries/ReactNativeClarity';
import ContextProvider from '../utils/ContextProvider';
import AnalyticsUtility from '../analytics/AnalyticsUtility';
import { isNative, isWeb } from '../utils/BooleanUtility';
import PermissionsUtility, {
  getNotificationStatus,
} from '../utils/PermissionsUtility';
import { createAndroidNotificationChannels } from '../utils/AndroidNotificationsUtils';
import {
  checkAppPlatform,
  checkInstallDateTimeFrame,
  checkIsDevicePremium,
} from '../utils/PersonalizationUtility';
import Session from '../utils/Sessions';
// import CleverTapAnalytics from '../analytics/CleverTapAnalytics';
import { getUserLocation } from '../actions/PersonalisedActions';
import { isTimestampCoveredInLastXhours } from '../utils/DateAndTimeUtility';
import DeviceInfoUtils from '../utils/DeviceInfoUtils';
import AppConfig from '../config/AppConfig';
import { isBlank, isPresent } from '../utils/BooleanUtility';
import BGNetworkTaskManager from '../utils/BGNetworkTaskManager';

let SmsRetriever;
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

if (
  Utility.isAndroid() &&
  Platform.Version >= ANDROID.BUILD.VERSIONS.MARSHMALLOW
) {
  SmsRetriever = require('react-native-sms-retriever').default;
}

const handleCustomTransition = ({ scenes }) => {
  const prevScene = scenes[scenes.length - 2];
  const nextScene = scenes[scenes.length - 1];
  // Custom transitions go there
  if (
    (prevScene && nextScene.route.name === 'WebUrlView') ||
    nextScene.route.name === 'ContentModal' ||
    nextScene.route.name === 'AttributeSelector'
  ) {
    return fromBottom();
  }
  if (
    (prevScene && nextScene.route.name === 'Address') ||
    nextScene.route.name === 'Payments' ||
    nextScene.route.name === 'PaytmVerify' ||
    nextScene.route.name === 'CartOffers' ||
    nextScene.route.name === 'PaytmWebView' ||
    nextScene.route.name === 'GooglePayProcessing' ||
    nextScene.route.name === 'Cart' ||
    nextScene.route.name === 'PostReview'
  ) {
    return null;
  }
  return fromRight();
};

const isInviteOnlyAccessEnabled = AppConfig.getBooleanValue(
  Config.ENABLE_INVITE_ONLY_ACCESS,
);

const isMandatoryLoginEnabled =
  isInviteOnlyAccessEnabled || EnableMandatoryLogin;

class SuperNavigator extends Component {
  constructor(props) {
    super(props);

    const { deviceId } = this.props;

    if (Utility.isPresent(Config.CLARITY_PROJECT_ID)) {
      const id = Utility.isPresent(deviceId) ? deviceId : undefined;
      initialize(Config.CLARITY_PROJECT_ID, {
        disableOnLowEndDevices: true,
      });

      if (Utility.isPresent(id)) {
        setCustomUserId(`${id}`);
      }
    }

    this.state = {
      currentOtp: '',
      onScreen: '',
    };
    this.debouncedNavigate = _.debounce(this.handleRouteFromLink, 50, {
      leading: true,
      trailing: false,
    });
    this.networkEventListener = null;
    this.networkEventSent = false;
    this.appStartedAt = new Date();
    this.registerSmsListenerRetryCount = 0;
    this.ignoreOnboardingRendering = true;
    this.extractOtpRegex = /\d{4}/;

    if (Utility.isAndroid()) {
      this.uploadManager = new NativeEventEmitter(NativeModules.UploadManager);
    } else {
      this.uploadManager = new NativeEventEmitter(
        NativeModules.LocalNotificationHandler,
        NativeModules.DeviceDetails,
      );
    }
    this.isBackPressed = false;
    this.appJustLaunched = true;
    this.isOpenedFromNotificationTap = false;

    const { appFirstOpened } = this.props;

    // DynamicLinkManager.appOpenedFromDynamicLink(this.debouncedNavigate, appFirstOpened);
    // DynamicLinkManager.detectDynamicLinkWhenAppIsRunning(
    //   this.debouncedNavigate,
    // );
    this.debounceVerifyOTP = _.debounce(this.verifyOtp, 1000);
    const { todayDeals: { membership_cohort = '', membership_id = '' } = {} } =
      this.props;
    Utility.membershipStatus = membership_cohort;
    Utility.membershipId = membership_id;
    checkAppPlatform();
    checkIsDevicePremium();
  }

  componentDidMount = () => {
    const {
      isRequestedFromHintPrompt,
      setPhoneNumberModalInfo,
      app_last_open_at,
      appLastOpenAt,
      last_app_killed_status,
      setAppKillReason,
      imageUrl,
      getAllReviews,
      isOtpRequestedFromHint,
      strPhoneInputModalInfo,
      saveBagModalOpenTimestampAndAppLaunchState,
      bagModalOpenTimeStamp,
      cartItems,
      appFirstOpened,
      appLaunchState,
      setAppFirstOpened,
      getMyProfileDetails,
      appInstalledAt,
      authToken,
      getUserLocation,
      locationUpdatedAt,
      syncRemoteOrders,
      guestToken,
      saveGuestAuthToken,
    } = this.props;

    this.hydrateAuthToken();

    if (SyncOrdersOnAppOpen && isPresent(authToken)) {
      syncRemoteOrders();
    }

    if (
      Utility.isBlank(authToken) &&
      (!guestToken || Utility.isBlank(guestToken))
    ) {
      this.randomGuestToken = uuidv7();
      saveGuestAuthToken(this.randomGuestToken);
    }

    Utility.imageUrl = imageUrl;
    const data = {
      appState: APP_LAUNCH_STATES.app_launched_with_cart_items,
    };

    const locationTimestampThresholdHours = RemoteConfig.getIntValue(
      REMOTE_CONFIG_KEYS.fetch_location_timestamp_threshold_hours,
    );

    if (
      cartItems.length >= 1 &&
      appLaunchState !== APP_LAUNCH_STATES.app_launched_with_cart_items
    ) {
      saveBagModalOpenTimestampAndAppLaunchState(data);
    }

    if (!isMandatoryLoginEnabled || isPresent(authToken)) {
      //TODO: Add a askPermissionOnAppStartUp on app startup, if app is opened from a dynamic link please ask this permission when firebase link is resolved
      setTimeout(() => {
        PermissionsUtility.askPermissionOnAppStartUp(
          PERMISSION_CONSTANTS.notification,
        );
      }, 1000);
    }

    // this.retrieveDeviceInfo();

    Utility.isAppLoadedForFirstTime = true;
    AudioHandler.initialize();

    if (isOtpRequestedFromHint) {
      isRequestedFromHintPrompt(false);
    }

    setPhoneNumberModalInfo(Utility.getLoginModalInfo('DEFAULT'));

    Platform.select({
      ios: null,
      android: this.registerSmsListener(),
      web: null,
    });

    if (Utility.isBlank(appFirstOpened)) {
      setAppFirstOpened(new Date());
    }
    if (
      isTimestampCoveredInLastXhours(
        locationUpdatedAt,
        locationTimestampThresholdHours,
      )
    ) {
      getUserLocation();
    }
    if (Utility.isBlank(app_last_open_at)) {
      appLastOpenAt(new Date());
    } else {
      Utility.appLastLoadedAtTimeDifference =
        Utility.differenceBetweenDate(app_last_open_at);
      appLastOpenAt(new Date());
    }
    AnalyticsUtility.fireAppLoadEventWithSource(appFirstOpened);
    if (Utility.isPresent(authToken)) {
      getMyProfileDetails();
    }
    if (Utility.isPresent(appInstalledAt)) {
      checkInstallDateTimeFrame(appInstalledAt);
    }
    // CleverTapAnalytics.enableSecondaryCTCommunicationChannels();
    if (Utility.isAndroid()) {
      this.uploadManager.addListener(
        'upload_notification_clicked',
        this.onUploadNotificationClicked,
      );

      this.uploadManager.addListener(
        'video_upload_notification_clicked',
        this.onVideoUploadNotificationClicked,
      );

      this.uploadManager.addListener(
        'firebase_deeplink_clicked',
        this.handleFirebaseNotification,
      );
    } else {
      this.uploadManager.addListener(
        'notification_clicked',
        this.onUploadManagerNotificationClickListener,
      );
    }

    this.resumeSelfieAnalysisFlow();
    if (Utility.isPresent(last_app_killed_status)) {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.APP_KILL_LAST_STATUS,
        {
          [EventParameterKey.REASON]: last_app_killed_status,
        },
      );
      setAppKillReason('');
    }
    // TODO: Test this on android as this was done only for ios in screen navigator
    AppState.addEventListener('change', this.handleAppStateChange);
    this.appInstalledFromArtistDefLink();

    if (Utility.isIOS()) {
      // Handling push notifications natively so commenting iOS related notification handling here
      // ReactMoE.registerForPush();
      this.updateLaunchedOption();
      this.fireDefaultUacEvents();
    }

    NotificationsHandler.setEventListeners(this.onClickNotification);
    NotificationsHandler.initialize(this.handleUrl);
    this.setAuthTokenForAndroid();
    AnalyticsManager.logBuildConfig();
    this.notificationHere();

    this.saveNotificationSounds();

    if (Utility.isIOS()) {
      setTimeout(this.getNotificationOnAppLaunch, 200);
      setTimeout(this.checkAndRetryUploadIOS, 0);
    }
    /**
     * Adding this function here for case:
     * For existing user, need to create new channels defined in remote config, whenever remote config data is
     * refreshed. Also need to check if any of the notification channel is blocked by user.
     */
    createAndroidNotificationChannels();
    PermissionsUtility.resetNotificationModalVisibilityForCurrentSession();
  };

  componentDidUpdate(prevProps) {
    const {
      imageUrl,
      smsRetrieverStatus,
      guestToken,
      authToken,
      syncRemoteOrders,
    } = this.props;
    const { guestToken: previousGuestToken, authToken: prevAuthToken } =
      prevProps;

    if (isPresent(prevAuthToken) && authToken === null) {
      this.hydrateAuthToken();
    }

    if (prevAuthToken === null && isPresent(authToken)) {
      this.saveAuthTokenToNativeLayer(authToken);
    }

    // To fix Notification Modal opening on top of Login Modal in case of mandatory login from feed.
    if (
      isMandatoryLoginEnabled &&
      isBlank(prevAuthToken) &&
      authToken !== prevAuthToken
    ) {
      setTimeout(() => {
        PermissionsUtility.askPermissionOnAppStartUp(
          PERMISSION_CONSTANTS.notification,
        );
      }, 1000);
    }

    if (prevProps?.imageUrl !== imageUrl) {
      Utility.imageUrl = imageUrl;
      updateSelfieUrl();
    }

    if (
      isBlank(prevAuthToken) &&
      prevAuthToken !== authToken &&
      Config.ORDER_PROVIDER === 'omuni'
    ) {
      syncRemoteOrders();
    }

    if (guestToken !== '' && previousGuestToken !== guestToken) {
      DeviceInfoUtils.retrieveDeviceInfo();
    }

    if (prevProps.smsRetrieverStatus !== smsRetrieverStatus) {
      if (smsRetrieverStatus === 'remove') {
        Platform.select({
          ios: null,
          android: this.unregisterSmsListener(),
          web: null,
        });
      } else if (smsRetrieverStatus === 'initiate') {
        Platform.select({
          ios: null,
          android: this.registerSmsListener(),
          web: null,
        });
      }
    }
  }

  componentWillUnmount = () => {
    const { setAppKillReason } = this.props;
    let reason = '';
    if (this.isBackPressed) {
      reason = 'back_button_press';
    } else {
      reason = 'non_clean_way';
    }
    setAppKillReason(reason);
    AnalyticsManager.logEvent(EventType.onboardingEvent.APP_KILLED, {
      [EventParameterKey.REASON]: reason,
    });
    AppState.removeEventListener('change', this.handleAppStateChange);
    NotificationsHandler.removeEventListeners();
    this.uploadManager.removeListener('upload_notification_clicked');
    this.uploadManager.removeListener('video_upload_notification_clicked');
    this.uploadManager.removeListener('iOS_device_info');
    this.uploadManager?.removeListener('selfie_skip_notification_clicked');
    DeviceEventEmitter?.removeListener('persistentNotificationClick');
  };

  saveAuthTokenToNativeLayer = (token) => {
    if (isWeb()) return;
    Keychain.setGenericPassword('authToken', token).then((response) => {
      console.tron.log('SUCCESS', response);
    });
  };

  hydrateAuthToken = () => {
    if (isWeb()) return;
    const { saveAuthTokenToDevice, authToken, refreshMyProfile, refreshMyAttributes, guestToken } =
      this.props;
    if (isPresent(authToken) || isPresent(guestToken)) {
      return;
    }

    Keychain.getGenericPassword().then((credentials) => {
      // Assuming auth token will always be more than 3 characters long
      if (credentials?.password && credentials.password?.length > 3) {
        saveAuthTokenToDevice(credentials.password);
        refreshMyProfile();
        refreshMyAttributes();
      }
    });
  };

  handleUrl = (notificationData) => {
    const { deeplink } = notificationData;
    if (!deeplink) {
      return;
    }
    DynamicLinkManager.handleDynamicLink(
      deeplink,
      this.debouncedNavigate,
      false,
    );
  };

  saveNotificationSounds = () => {
    const notificationSoundsJson = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.notification_sounds,
    );
    const notificationSounds = Utility.jsonParser(notificationSoundsJson);
    NotificationSoundsManager.saveNotificationSounds(notificationSounds);
  };

  getNotificationOnAppLaunch = () => {
    NotificationManager.getNotificationOnAppLaunch();
  };

  notificationHere = () => {
    DeviceEventEmitter.addListener(
      'persistentNotificationClick',
      ({ action, cta, type }) => {
        const notification = {};

        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.NOTIFICATION_ACTION,
          {
            action: action,
            type,
            cta,
            source: 'local',
          },
        );
        if (action !== 'continue') {
          NotificationManager.clearNotifications();
        }
        notification.url = action;
        notification.type = type;
        if (!Utility.isBlank(action)) {
          this.onPersistentNotificationClickListener(notification);
        }
      },
    );
  };

  setAuthTokenForAndroid = () => {
    const { authToken } = this.props;
    if (!Utility.isBlank(authToken) && Utility.isAndroid()) {
      NativeModules.UserPreferences.saveAuthToken(`${authToken}`);
    }
  };

  loginAndSetUpdatedUserAttributesToCleverTap = () => {
    const {
      mobileNumber,
      userRegisteredOnCt,
      isUserRegisteredOnCt,
      deviceId,
      publicId,
      profile: { name = '', email = '' },
      guestToken,
      membershipCohort,
      membershipId,
    } = this.props;
    if (!mobileNumber || isUserRegisteredOnCt) return;

    const user = {
      profile: {
        Name: name,
        Email: email,
        Phone: mobileNumber,
      },
      properties: {
        ssid: deviceId,
        user_id: publicId,
        guest_token: guestToken,
        membership_cohort: membershipCohort,
        membership_id: membershipId,
        authenticated: true,
      },
    };
    // CleverTapAnalytics.setUserPropertiesToUpdatedUsers(user);
    userRegisteredOnCt(true);
  };

  onUploadNotificationClicked = ({ retryClicked }) => {
    if (retryClicked) {
      const { retrySelfieAnalysis } = this.props;
      /**
       * @method retrySelfieAnalysis, retry whole analysis process again,
       * @params : analysisReviewPopupRequired = boolean, If you want to show analysisReviewModal After this process completion or not.
       */

      AnalyticsManager.logEvent(EventType.onboardingEvent.SELFIE_UPLOAD_RETRY, {
        [EventParameterKey.SOURCE]: EventParameterValue.SOURCE.NOTIFICATION,
      });
      retrySelfieAnalysis(true);
    }
  };

  onVideoUploadNotificationClicked = ({ action }) => {
    const {
      publishPost,
      setVideoUploadProgress,
      lastVideoUpload: { id, postData },
      showUploadTrayOnFeed,
      abortMediaUpload,
    } = this.props;
    if (action === 'cancel') {
      NotificationManager.hideVideoUploadingNotification();
      showUploadTrayOnFeed(false);
      abortMediaUpload();
    } else if (action === 'mark_failed') {
      abortMediaUpload();
    } else if (action === 'retry_video_upload') {
      Utility.setVideoUploadProgress = setVideoUploadProgress;
      NotificationManager.hideVideoUploadingNotification();
      publishPost(id, postData);
    }
  };

  resumeSelfieAnalysisFlow = () => {
    const {
      retrySelfieAnalysis,
      facialAnalysis: { analysis_stage = '', image_id, user_attributes },
      resumeSelfieFromUserAttributeFetching,
      resumeSelfieFromUserAttributesMapping,
      profile: { local_image_uri = '' },
    } = this.props;
    if (
      (analysis_stage === SELFIE_STATE.QUEUED ||
        analysis_stage === SELFIE_STATE.STARTING) &&
      Utility.isPresent(local_image_uri)
    ) {
      retrySelfieAnalysis(true);
    } else if (analysis_stage === SELFIE_STATE.SELFIE_UPLOADED) {
      resumeSelfieFromUserAttributeFetching(image_id, true);
    } else if (analysis_stage === SELFIE_STATE.STARTED_ATTRIBUTES_MAPPING) {
      resumeSelfieFromUserAttributesMapping(user_attributes);
    }
  };

  handleBackpress = () => {
    this.isBackPressed = true;
  };

  handleAppStateChange = (nextAppState) => {
    if (nextAppState === 'background') {
      AnalyticsManager.logEvent(EventType.appLifeCycleEvents.APP_BACKGROUND);
      this.appJustLaunched = true;
      this.checkAndShowNotification(nextAppState);
    } else {
      if (nextAppState === 'active') {
        this.appJustLaunched = false;
      }
      if (nextAppState === 'inactive') {
        this.appJustLaunched = true;
      }
    }
  };

  checkAndShowNotification = (nextAppState) => {
    const { navigation } = this.props;
    this.uploadManager.addListener(
      'selfie_skip_notification_clicked',
      ({ action }) => {
        this.isOnboardingSkipNotificationClicked = true;
        NotificationManager.cancelSelfieSkipNotification();
        if (action === 'skip') {
          this.skip();
        } else if (action === 'continue') {
          navigation.navigate('SelfieIntro');
        }
      },
    );
  };

  appInstalledFromArtistDefLink = () => {
    if (isWeb()) return;
    const { setAppInstallSource, appInstalledSource } = this.props;

    NativeModules.InstallSource.appInstalledFromFbDeferredLink(
      (err, installFromFbDefLink) => {
        // appInstalledSource===undefined means appInstalledSource was not set before
        if (Utility.isPresent(err)) {
          return;
        }
        if (appInstalledSource === undefined) {
          if (installFromFbDefLink) {
            setAppInstallSource('fb_artist_def_link');
          } else {
            setAppInstallSource('organic');
          }
        }
      },
    );
  };

  updateLaunchedOption = async () => {
    // console.tron.log('calling');
    await sleep(2000);
    this.appJustLaunched = false;
  };

  fireDefaultUacEvents = () => {
    const { uacBeautyAppEventTriggered } = this.props;
    const defaultIosDeviceScore = RemoteConfig.getIntValue(
      REMOTE_CONFIG_KEYS.default_ios_device_score,
    );

    const defaultIosBeautyAppsScore = RemoteConfig.getIntValue(
      REMOTE_CONFIG_KEYS.default_ios_beauty_apps_score,
    );

    const defaultIosCommerceAppsScore = RemoteConfig.getIntValue(
      REMOTE_CONFIG_KEYS.default_ios_commerce_app_score,
    );

    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_DEVICE_SCORE, {
      [EventParameterKey.SCORE]: defaultIosDeviceScore,
    });
    AnalyticsManager.logEvent(
      EventType.onboardingEvent.UAC_COMMERCE_APPS_SCORE,
      {
        [EventParameterKey.SCORE]: defaultIosCommerceAppsScore,
      },
    );
    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_BEAUTY_APPS_SCORE, {
      [EventParameterKey.SCORE]: defaultIosBeautyAppsScore,
    });
    const uac = {
      deviceScore: defaultIosDeviceScore,
      beautyAppScore: defaultIosBeautyAppsScore,
      commerceAppScore: defaultIosCommerceAppsScore,
    };
    uacBeautyAppEventTriggered(uac);
  };

  onClickNotification = (notification) => {
    Logger.logMessage('OnClickNotification', notification);
    const { data: { payload = {} } = {} } = notification;

    let notificationObject = {};
    if (payload.push_from === 'moengage') {
      notificationObject = {
        source: 'moengage',
        notification_channel: payload.moe_channel_id,
        title: payload.gcm_title,
        target_deeplink: payload?.gcm_webUrl?.split('?')[0],
      };
    } else if (Utility.isPresent(notification.wzrk_acct_id)) {
      notificationObject = {
        source: 'clevertap',
        notification_channel: notification.wzrk_cid,
        title: notification.nt,
        target_deeplink: notification?.wzrk_dl?.split('?')[0],
      };
    }

    AnalyticsManager.logEvent(
      EventType.appLifeCycleEvents.NOTIFICATION_ACTION,
      notificationObject,
    );
    this.handleNotificationTapAndDeepLink(notification);
  };

  onUploadManagerNotificationClickListener = (notification) => {
    if (!this.isOpenedFromNotificationTap) {
      Logger.logMessage(
        'onUploadManagerNotificationClickListener',
        notification,
      );
      this.handleNotificationTapAndDeepLink(notification);
      this.isOpenedFromNotificationTap = true;
    }
  };

  onPersistentNotificationClickListener = (notification) => {
    Logger.logMessage('onPersistentNotificationClickListener', notification);
    this.handleNotificationTapAndDeepLink(notification);
  };

  onLinkingEventListener = (notification) => {
    if (!this.isOpenedFromNotificationTap) {
      Logger.logMessage('onLinkingEventListener', notification);
      this.handleNotificationTapAndDeepLink(notification);
      this.isOpenedFromNotificationTap = true;
    }
  };

  handleNotificationTapAndDeepLink = (notification) => {
    const firebaseDeeplink =
      notification.firebase_deeplink ?? notification.payload?.firebase_deeplink;
    if (Utility.isPresent(firebaseDeeplink)) {
      this.handleFirebaseNotification(notification);
      this.isOpenedFromNotificationTap = false;
      return;
    }
    const { updateLocalNotificationData } = this.props;
    updateLocalNotificationData(notification.type);
    let action = '';
    if (
      Utility.isPresent(notification.payload) &&
      Utility.isPresent(notification.payload.app_extra) &&
      Utility.isPresent(notification.payload.app_extra.moe_deeplink)
    ) {
      action = notification.payload.app_extra.moe_deeplink;
      this.handleDynamicLinkAction(action);
    } else if (
      Utility.isPresent(
        notification.payload?.moeFeatures?.richPush?.defaultActions?.[0].value,
      )
    ) {
      action =
        notification.payload.moeFeatures.richPush.defaultActions[0].value;
      this.handleDynamicLinkAction(action);
    } else if (Utility.isPresent(notification.action?.[0]?.action?.value)) {
      action = notification.action[0].action.value;
      this.handleDynamicLinkAction(action);
    } else if (
      !Utility.isBlank(notification.url) &&
      !notification.url.includes(LINK_DOMAIN_SHORT)
    ) {
      action = notification.url;
      DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
        action,
        this.debouncedNavigate,
      );
      // }
    } else if (
      Utility.isPresent(notification.payload) &&
      Utility.isPresent(notification.payload.wzrk_dl)
    ) {
      DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
        notification.payload.wzrk_dl,
        this.debouncedNavigate,
      );
    }
    if (Utility.isIOS()) {
      Session.setInitialAppDeeplink(action);
      this.fireLiveActivityActionEventsIOS(notification);
    }
    this.isOpenedFromNotificationTap = false;
  };

  fireLiveActivityActionEventsIOS = (notification) => {
    let action = '';
    const { url = '' } = notification;
    switch (url) {
      case CART_NOTIFICATION_ADD_ADDRESS_URL:
        action = 'add_address';
        break;
      case CART_NOTIFICATION_MAKE_PAYMENT_URL:
        action = 'make_payment';
        break;
      case CART_NOTIFICATION_PAYMENT_DONE_URL:
        action = 'payment_done';
        break;
      default:
        break;
    }

    if (Utility.isBlank(action)) {
      return;
    }
    AnalyticsManager.logEvent(
      EventType.appLifeCycleEvents.NOTIFICATION_ACTION,
      {
        action,
        type: 'live_activity',
        cta: url,
        source: 'live_activity',
      },
    );
  };

  handleFirebaseNotification = (notification) => {
    const firebaseDeeplink =
      notification.firebase_deeplink ?? notification.payload?.firebase_deeplink;
    if (Utility.isPresent(firebaseDeeplink)) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.NOTIFICATION_ACTION,
        {
          action: firebaseDeeplink,
          type: EventParameterKey.FIREBASE,
        },
      );
      this.handleDynamicLinkAction(firebaseDeeplink);
    }
  };

  handleDynamicLinkAction = (action) => {
    if (action.includes(LINK_DOMAIN_SHORT)) {
      DynamicLinkManager.resolveLink(action, this.debouncedNavigate, false);
      return;
    }
    // TODO: Create notification handler for custom key value pair
    const { appFirstOpened } = this.props;
    AnalyticsUtilty.fireAppLoadEvent(
      APP_LOAD_SOURCE.NOTIFICATION,
      appFirstOpened,
    );
    this.openedFromNotification = true;
    DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
      action,
      this.debouncedNavigate,
    );
  };

  replaceCurrentGuestToken = (newGuestToken) => {
    const { guestFirstAppOpenTime, saveGuestAuthToken, registerGuestUser } =
      this.props;
    if (
      TimeUtility.getDiffInMinsFromCurrentTime(guestFirstAppOpenTime) <
      TIME_RANGE_TO_REPLACE_GUEST_TOKEN
    ) {
      saveGuestAuthToken(newGuestToken);
      BGNetworkTaskManager.saveGuestAuthToken(newGuestToken);
      AnalyticsUtilty.setGuestTokenAsProperties(newGuestToken);
      registerGuestUser('deffered_link');
    }
  };

  handleRouteFromLink = ({
    route = '',
    slug = '',
    path = '',
    extra = {},
    params = {},
    url = '',
  }) => {
    const { saveBagModalOpenTimestampAndAppLaunchState } = this.props;

    const data = {
      appState: APP_LAUNCH_STATES.app_opened_with_deep_link,
    };
    saveBagModalOpenTimestampAndAppLaunchState(data);

    const { navigation, authToken } = this.props;
    const { gt = '' } = extra;
    if (Utility.isPresent(gt)) {
      this.replaceCurrentGuestToken(gt);
    }

    if (extra.externalActionRequired) {
      if (route === 'ContentModal' && path !== 'youtube_offer') {
        const { listId, videoId } = extra;
        this.getVideoListDetailById(listId, videoId);
      } else if (route === 'ContentModal' && path === 'youtube_offer') {
        const { couponCode, videoSlug } = extra;

        this.applyYoutubeOffer(couponCode, videoSlug);
      }
    } else if (Utility.isPresent(authToken)) {
      if (params?.notification_action === 'replace') {
        NavigationService.deeplinkHandledNavigation(route, {
          slug,
          extra,
          params,
          url,
        });
        return;
      }

      NavigationService.deeplinkHandledNavigation(route, {
        slug,
        extra,
        params,
        url,
        destination: url,
      });
    } else {
      this.dynamicLinkNavigator(route, slug, params, extra, url);
    }
    this.isOpenedFromNotificationTap = false;
  };

  getVideoListDetailById = (listId, videoId) => {
    const { getListDetails, navigation } = this.props;
    getListDetails(listId, (success, response) => {
      const selectedVideoIndex = Utility.deriveIndexFromListObject(
        response.objects,
        videoId,
      );
      NavigationService.push('ContentModal', {
        listId,
        index: Utility.deriveIndexFromListObject(response.objects, videoId),
        itemData: response.objects[selectedVideoIndex],
        listData: response,
      });
    });
  };

  applyYoutubeOffer = (couponCode, videoSlug) => {
    const { applyCartCoupon, updateMediaId, navigation } = this.props;
    let showToast = false;
    applyCartCoupon(
      couponCode,
      (response) => {
        showToast = response;
      },
      true,
    );
    updateMediaId({
      id: 0,
      slug: `/api/v1/foxy_videos/${videoSlug}/youtube_offer_video`,
      callback: (response) => {
        if (response) {
          NavigationService.push('ContentModal', {
            showToast,
            listId: response.id,
            index: 0,
            itemData: response.objects[0],
            listData: response,
          });
        }
      },
    });
    // this.getVideoListDetailById();
  };

  dynamicLinkNavigator = (route, slug, params = {}, extra, url) => {
    const {
      authToken,
      saveUserState,
      guestProfile,
      saveBagModalOpenTimestampAndAppLaunchState,
    } = this.props;

    const data = {
      appState: APP_LAUNCH_STATES.app_opened_with_deep_link,
    };
    saveBagModalOpenTimestampAndAppLaunchState(data);

    Utility.navigationParams = params;
    Utility.skipOnboardingSelfie = params.skip_selfie || params.skip_selfi;
    const { current_state } = guestProfile;

    if (
      (current_state !== CURRENT_GUEST_STATE.SKIPPED ||
        current_state === CURRENT_GUEST_STATE.INITIAL) &&
      !Utility.tabNavigatorReplaced
    ) {
      saveUserState(CURRENT_GUEST_STATE.SKIPPED);
      setTimeout(() => {
        NavigationService.replace('TabNavigator');
      }, 30);

      Utility.isReplacedFromAppNavigator = true;
      Utility.tabNavigatorReplaced = true;
    }
    if (params?.notification_action === 'replace') {
      NavigationService.deeplinkHandledNavigation(route, {
        slug,
        params,
        extra,
        destination: url,
        from_deeplink: true,
      });
      return;
    }

    setTimeout(() => {
      NavigationService.deeplinkHandledNavigation(route, {
        slug,
        params,
        extra,
        destination: url,
        from_deeplink: true,
      });
    }, 30);
  };

  registerSmsListener = async () => {
    const { authToken } = this.props;
    if (Utility.isAndroid() && Utility.isBlank(authToken)) {
      try {
        const registered = await SmsRetriever.startSmsRetriever();
        if (registered) {
          this.registerSmsListenerRetryCount = 0;
          AnalyticsManager.logEvent(
            EventType.onboardingEvent.ANDROID_SMS_RETRIEVER_SETUP,
          );
          this.onMessageReceiveListener();
        } else {
          this.registerSmsListenerRetryCount++;
          await this.retrySmsListenerRegistration(this.registerSmsListener);
        }
      } catch (error) {
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.ANDROID_SMS_RETRIEVER_ERROR,
          {
            [EventParameterKey.ERROR]: JSON.stringify(error),
          },
        );
        this.registerSmsListenerRetryCount++;
        await this.retrySmsListenerRegistration(this.registerSmsListener);
      }
    }
  };

  retrySmsListenerRegistration = async (furtherAction) => {
    const { authToken } = this.props;
    if (Utility.isAndroid() && Utility.isBlank(authToken)) {
      const timeouts = { 1: 2000, 2: 3000, 3: 5000, 4: 10000 };
      if (this.registerSmsListenerRetryCount > 4) {
        return;
      }
      const currentRetryCycleTimeout =
        timeouts[this.registerSmsListenerRetryCount] || 5000;
      const countDownTime = setTimeout(() => {
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.ANDROID_SMS_LISTENER_REGISTER_RETRY,
          {
            [EventParameterKey.RETRY_COUNT]: this.registerSmsListenerRetryCount,
          },
        );

        furtherAction();
        clearTimeout(countDownTime);
      }, currentRetryCycleTimeout);
    }
  };

  getActiveRouteName = (navigationState) => {
    if (!navigationState) {
      return null;
    }
    const route = navigationState.routes[navigationState.index];
    if (route.routes) {
      return this.getActiveRouteName(route);
    }
    return route.name;
  };

  onMessageReceiveListener = async () => {
    const { authToken } = this.props;
    if (Utility.isAndroid() && Utility.isBlank(authToken)) {
      try {
        SmsRetriever.addSmsListener(this.onReceiveSms);
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.ANDROID_SMS_LISTENER_SETUP,
        );
      } catch (error) {
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.ANDROID_SMS_LISTENER_ERROR,
          {
            [EventParameterKey.ERROR]: JSON.stringify(error),
          },
        );
      }
    }
  };

  unregisterSmsListener = () => {
    if (Utility.isAndroid()) {
      try {
        SmsRetriever.removeSmsListener();
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.ANDROID_SMS_LISTENER_UNREGISTER,
        );
      } catch (error) {
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.ANDROID_SMS_LISTENER_UNREGISTER_ERROR,
          {
            [EventParameterKey.ERROR]: JSON.stringify(error),
          },
        );
      }
    }
  };

  onReceiveSms = (event) => {
    const { message } = event;
    const { isOtpRequestedFromHint, storeCurrentOtp } = this.props;

    if (this.extractOtpRegex.test(message)) {
      // FIXME: This gets fired several times in a row. Needs to be debounced.
      AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_DETECT);
      const otp = message.match(this.extractOtpRegex)[0];
      storeCurrentOtp(otp);

      if (isOtpRequestedFromHint) {
        NavigationService.unmountOnboarding(); // Added to remove unresponsive onboarding screen after hint prompt login.
        AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_FILL, {
          [EventParameterKey.INPUT_METHOD]:
            EventParameterValue.INPUT_METHOD.BACKGROUND,
        });
        this.debounceVerifyOTP(otp);
      }
    }
  };

  verifyOtp = (otp) => {
    const { verifyOtp } = this.props;
    verifyOtp(otp, (error) => {
      if (error) {
        AnalyticsManager.logEvent(EventType.onboardingEvent.OTP_WRONG, {
          [EventParameterKey.INPUT_METHOD]:
            EventParameterValue.INPUT_METHOD.AUTO_READ,
          [EventParameterKey.ERROR]: error,
        });
        if (Utility.isPresent(Utility.furtherAction.phone_number)) {
          this.unregisterSmsListener();
          Utility.furtherAction = {
            action: null,
            params: [],
            phone_number: '',
            isActionAsync: false,
          };
        }
      } else {
        AnalyticsManager.logEvent(EventType.onboardingEvent.ACCOUNT_LOGIN);
        AnalyticsManager.logFirebaseEvent(
          EventType.googleRemarketingEvents.LOGIN,
          {
            [EventParameterKey.METHOD]:
              EventParameterValue.INPUT_METHOD.AUTO_READ,
          },
        );

        const fbMeta = {
          [EventParameterKey.FB.EVENT_PARAM_REGISTRATION_METHOD]:
            EventParameterValue.INPUT_METHOD.AUTO_READ,
        };

        AnalyticsManager.logFBStandardEvent(
          EventType.FB.EVENT_NAME_COMPLETED_REGISTRATION,
          null,
          AnalyticsUtilty.addCurrencyToFBEventMetaData(fbMeta),
        );
        this.unregisterSmsListener();
      }
    });
  };

  RenderOnboarding = (props) => {
    const { shouldRender, otp } = props;
    if (this.ignoreOnboardingRendering) {
      this.ignoreOnboardingRendering = false;
      return null;
    }
    if (shouldRender) {
      return <Onboarding otp={otp} />;
    }
    return null;
  };

  checkAndRetryUploadIOS = () => {
    const {
      publishPost,
      setVideoUploadProgress,
      lastVideoUpload: { id, postData },
      showUploadTrayOnFeed,
      abortMediaUpload,
    } = this.props;
    if (id && postData) {
      Utility.setVideoUploadProgress = setVideoUploadProgress;
      publishPost(id, postData);
    } else {
      showUploadTrayOnFeed(false);
      abortMediaUpload();
    }
  };

  render() {
    const { currentOtp, onScreen } = this.state;
    const {
      todayDeals: { membership_cohort = '', is_upgradable },
    } = this.props;
    const data = { membership_cohort, is_upgradable };
    return (
      <>
        {/* <StatusBar backgroundColor='black' barStyle='light-content' /> */}
        <ContextProvider.userContext.Provider value={data}>
          <AppStack />
        </ContextProvider.userContext.Provider>
      </>
    );
  }
}

const AppNavigator = SuperNavigator;

//SuperNavigator.propTypes = {
//  isOtpRequestedFromHint: PropTypes.bool,
//  smsRetrieverStatus: PropTypes.string,
//  app_last_open_at: PropTypes.instanceOf(Date),
//  facialAnalysis: PropTypes.any,
//  profile: PropTypes.shape({
//    name: PropTypes.string,
//    email: PropTypes.string,
//    locality: PropTypes.string,
//    handle: PropTypes.string,
//  }),
//  last_app_killed_status: PropTypes.string,
//  lastVideoUpload: PropTypes.shape({
//    id: PropTypes.string,
//    postData: PropTypes.string,
//    status: PropTypes.string,
//    progress: PropTypes.number,
//  }),
//  authToken: PropTypes.string,
//  appInstalledSource: PropTypes.string,

//  navigation: PropTypes.any,
//  isSpecialOfferApplied: PropTypes.bool,
//  cartItems: PropTypes.any,
//  uploadScannedImage: PropTypes.func,
//  storeCurrentOtp: PropTypes.func,
//  verifyOtp: PropTypes.func,
//  isRequestedFromHintPrompt: PropTypes.func,
//  appLastOpenAt: PropTypes.func,
//  setPhoneNumberModalInfo: PropTypes.func,
//  retrySelfieAnalysis: PropTypes.func,
//  resumeSelfieFromUserAttributeFetching: PropTypes.func,
//  resumeSelfieFromUserAttributesMapping: PropTypes.func,
//  setAppKillReason: PropTypes.func,
//  publishPost: PropTypes.func,
//  setVideoUploadProgress: PropTypes.func,
//  setAppInstallSource: PropTypes.func,
//  getListDetails: PropTypes.func,
//  applyCartCoupon: PropTypes.func,
//  updateMediaId: PropTypes.func,
//  uacDeviceEventTriggered: PropTypes.func,
//  uacCommerceAppEventTriggered: PropTypes.func,
//  uacBeautyAppEventTriggered: PropTypes.func,
//  saveRefferalCode: PropTypes.func,
//};

SuperNavigator.defaultProps = {
  isOtpRequestedFromHint: null,
  smsRetrieverStatus: null,
  app_last_open_at: null,
  facialAnalysis: null,
  profile: PropTypes.shape({
    name: null,
    email: null,
    locality: null,
    handle: null,
  }),
  last_app_killed_status: null,
  lastVideoUpload: PropTypes.shape({
    id: null,
    postData: null,
    status: null,
    progress: 0,
  }),
  authToken: null,
  appInstalledSource: null,
  navigation: null,
  isSpecialOfferApplied: false,
  cartItems: null,
  uploadScannedImage: () => {},
  storeCurrentOtp: () => {},
  verifyOtp: () => {},
  isRequestedFromHintPrompt: () => {},
  appLastOpenAt: () => {},
  setPhoneNumberModalInfo: () => {},
  retrySelfieAnalysis: () => {},
  resumeSelfieFromUserAttributeFetching: () => {},
  resumeSelfieFromUserAttributesMapping: () => {},
  setAppKillReason: () => {},
  publishPost: () => {},
  setVideoUploadProgress: () => {},
  setAppInstallSource: () => {},
  getListDetails: () => {},
  applyCartCoupon: () => {},
  updateMediaId: () => {},
  uacDeviceEventTriggered: () => {},
  uacCommerceAppEventTriggered: () => {},
  uacBeautyAppEventTriggered: () => {},
  saveRefferalCode: () => {},
};

const mapStateToProps = (state) => ({
  isOtpRequestedFromHint: state.UserAccountInfo.requestedFromHintPrompt,
  smsRetrieverStatus: state.UserAccountInfo.smsRetrieverStatus,
  app_last_open_at: state.UserAccountInfo.app_last_open_at,
  facialAnalysis: state.UserAccountInfo.facialAnalysis,
  profile: state.UserAccountInfo.profile,
  last_app_killed_status: state.UserAccountInfo.last_app_killed_status,
  lastVideoUpload: state.UserAccountInfo.lastVideoUpload,
  authToken: state.UserAccountInfo.authToken,
  appInstalledSource: state.UserAccountInfo.appInstalledSource,
  guestProfile: state.UserAccountInfo.guestProfile,
  isSpecialOfferApplied: state.UserAccountInfo.isSpecialOfferApplied,
  cartItems: state.bag.cartItems,
  imageUrl: state.UserAccountInfo.profile.selfie_image_url,
  strPhoneInputModalInfo: state?.UserAccountInfo?.phoneNumberModalInfo,
  guestFirstAppOpenTime: state.UserAccountInfo.guestProfile.firstOpenTime,
  bagModalOpenTimeStamp: state.bag.bagModalOpenTimeStamp,
  appLaunchState: state.bag.appLaunchState,
  todayDeals: state?.todayDeals,
  appFirstOpened: state.UserAccountInfo.appFirstOpened,
  deviceId: state.UserAccountInfo.deviceId,
  appInstalledAt: state.UserAccountInfo.app_installed_at,
  mobileNumber: state.UserAccountInfo.mobileNumber,
  publicId: state.UserAccountInfo.publicId,
  guestToken: state.UserAccountInfo.guestProfile?.guestAuthToken,
  membershipCohort: state.UserAccountInfo.todayDeals?.membership_cohort,
  membershipId: state.UserAccountInfo.todayDeals?.membership_id,
  isUserRegisteredOnCt: state.UserAccountInfo.userRegisteredOnCt,
  locationUpdatedAt: state.UserAccountInfo.locationUpdatedAt,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      uploadScannedImage,
      storeCurrentOtp,
      verifyOtp,
      isRequestedFromHintPrompt,
      appLastOpenAt,
      setPhoneNumberModalInfo,
      retrySelfieAnalysis,
      resumeSelfieFromUserAttributeFetching,
      resumeSelfieFromUserAttributesMapping,
      setAppKillReason,
      publishPost,
      setVideoUploadProgress,
      setAppInstallSource,
      getListDetails,
      applyCartCoupon,
      updateMediaId,
      uacDeviceEventTriggered,
      uacCommerceAppEventTriggered,
      uacBeautyAppEventTriggered,
      saveRefferalCode,
      postDeviceInformation,
      saveUserState,
      showUploadTrayOnFeed,
      abortMediaUpload,
      setVideoUploadStatus,
      saveGuestAuthToken,
      registerGuestUser,
      saveBagModalOpenTimestampAndAppLaunchState,
      updateLocalNotificationData,
      setAppFirstOpened,
      getMyProfileDetails,
      userRegisteredOnCt,
      getUserLocation,
      syncRemoteOrders,
      saveAuthTokenToDevice,
      refreshMyProfile,
      refreshMyAttributes,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigator);
