import { camelCase, upperFirst } from 'lodash';
import { lazy } from 'react';
import { LinkCardRail } from '../../links/LinkCard';

const Collection = lazy(() => import('../../../containers/collection/Collection'));
const Salon = lazy(() => import('../../../containers/salon'));
const Product = lazy(() => import('../../../containers/product'));
const UsersAttribute = lazy(() => import('../../../containers/UserAttributes/UsersAttribute')); 
const DefaultSizeProductRail = lazy(() => import('../../Product/DefaultSizeProductRail'));
const LargeProductRail = lazy(() => import('../../Product/LargeProductRail'));
const HorizontalProductRail = lazy(() => import('../../Product/HorizontalProductRail'));
const IngredientCollectionCard = lazy(() => import('../../Collection/IngredientCollectionCard'));
const FeatureCard = lazy(() => import('../../feature/FeatureCard'));
const TallBanner = lazy(() => import('../../tall-banner/TallBanner'));
const RoutineRailWithDescriptionCard = lazy(() => import('../../Routines/RoutineRailWithDescriptionCard'));
const SlimVariantRail = lazy(() => import('../../variants/SlimVariantRail'));
const SlimProductRail = lazy(() => import('../../Product/SlimProductRail'));
const DynamicProductRail = lazy(() => import('../../Product/DynamicProductRail'));
const DynamicVariantRail = lazy(() => import('../../variants/DynamicVariantRail'));
const DefaultSizeProductFeaturedRail = lazy(() => import('../../Product/DefaultSizeProductFeaturedRail'));
const DefaultSizeOfferPromptFancyRail = lazy(() => import('../offerPrompt/DefaultSizeOfferPromptFancyRail'));
const FancyCard = lazy(() => import('../../../containers/FoxyEdge/FancyCard'));
const FancyHeroCard = lazy(() => import('../../../containers/FoxyEdge/FancyHeroCard'));
const DefaultSizeVerticalBanner = lazy(() => import('../../../containers/defaultSizeVerticalBanner/DefaultSizeVerticalBanner'));
const Tag = lazy(() => import('../../../containers/tag/Tag'));
const Category = lazy(() => import('../../../containers/category/Category'));
const DefaultSizeBrandRail = lazy(() => import('../../brand/DefaultSizeBrandRail'));
const DefaultSizeArtistRail = lazy(() => import('../../Artist/DefaultSizeArtistRail'));
const DefaultSizeVariantRail = lazy(() => import('../../variants/DefaultSizeVariantRail'));
const LargeVariantRail = lazy(() => import('../../variants/LargeVariantRail'));
const SmallVariantRail = lazy(() => import('../../variants/DefaultSizeVariantRail'));
const DefaultSizeVideoRail = lazy(() => import('../../media/DefaultSizeVideoRail'));
const DefaultSizeDermatProfileRail = lazy(() => import('../../pro/DefaultSizeDermatProfileRail'));


// import { SmallBrandRail, LargeBrandRail } from '../../brand/OtherBrands';
// import { LinkCardRail } from '../../links/LinkCard';

const Components = {
  salon: Salon,
  product: Product,
  list: Collection,
  product_category: Collection,
  brand: Collection,
  media_category: Category,
  tag: Tag,
  feature: FeatureCard,
  user_attribute_allowed_value: UsersAttribute,
  DefaultSizeProductRail,
  DefaultSizeVariantRail,
  HorizontalProductRail,
  LargeProductRail,
  SmallProductRail: DefaultSizeProductRail,
  LargeVariantRail,
  SmallVariantRail,
  DefaultSizeVideoRail,
  SmallVideoRail: DefaultSizeVideoRail,
  LargeVideoRail: DefaultSizeVideoRail,
  DefaultSizeArtistRail,
  SmallArtistRail: DefaultSizeArtistRail,
  LargeArtistRail: DefaultSizeArtistRail,
  DefaultSizeBrandRail,
  SmallBrandRail: DefaultSizeBrandRail,
  LargeBrandRail: DefaultSizeBrandRail,
  ingredient: IngredientCollectionCard,
  offer: Collection,
  tall_banner: TallBanner,
  routine: RoutineRailWithDescriptionCard,
  pro_link: LinkCardRail,
  SlimVariantRail,
  SlimProductRail,
  DefaultSizeProductFeaturedRail,
  DynamicProductRail,
  DynamicVariantRail,
  DefaultSizeOfferPromptFancyRail,
  DefaultSizeVerticalBanner,
  DefaultSizeDermatProfileRail,
};

export const getRailComponent = (
  cardType,
  content,
  size,
  display,
  noOfListItems,
) => {
  let CardContainer = Components[content];
  if (
    content === 'product' ||
    content === 'sku' ||
    content === 'media' ||
    content === 'artist' ||
    content === 'brand' ||
    content === 'offer_prompt' ||
    content === 'dermat_profile'
  ) {
    if (display === 'fancyRail' && ['product', 'sku'].includes(content)) {
      CardContainer = noOfListItems > 1 ? FancyCard : FancyHeroCard;
    } else {
      CardContainer = Components[`${upperFirst(size)}${upperFirst(camelCase(cardType))}${upperFirst(display)}`];
    }
  }

  if (content === 'mixed') {
    CardContainer = Components[cardType];
  }
  if (content === 'banner') {
    CardContainer = Components[`${upperFirst(size)}${upperFirst(display)}`];
  }
  return CardContainer;
};

export const getRailHeight = (item) => {
  const { objects = [], content, size, display } = item;
  const ContainerComponent = getRailComponent(
    objects[0]?.type,
    content,
    size,
    camelCase(display),
  );
  try {
    return ContainerComponent.getComponentHeight(item);
  } catch (error) {
    return 0;
  }
};
