import React, { useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Clipboard,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Share from '../../libraries/ReactNativeShare';
import { WEB_URL } from '../../config/Constants';
import StyleSheet from 'react-native-media-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import Toast from 'react-native-easy-toast';
import { ids, styles } from './styles';
import { isDesktop, isNative, isWeb } from '../../utils/BooleanUtility';
import images from '../../theme/Images';
import DesktopHeaderLogo from '../shared/DesktopHeaderLogo';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import SaveProfileButton from './SaveProfileButton';
import { refreshMyProfile } from '../../actions/ActionTypes';
import { StaticNavigationHeader } from '../shared';
import Utility from '../../utils/Utility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import Header from './Header';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

export default function OnboardCustomers() {
  const navigation = useNavigation();
  const toastRef = useRef(null);
  const handle = useSelector((state) => state.UserAccountInfo.profile.handle) || '';
  const name = useSelector((state) => state.UserAccountInfo.profile.name) || '';
  const shareTemplate = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.share_handle)) || {};
  const headerData = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_onbaord_pages_Header_text)) || {};
  const headerTitle = headerData['onboardCustomers']?.title;
  const headerSubtitle = headerData['onboardCustomers']?.subtitle;
  const {
    invite_patients_card_content = [],
    cta_text = '',
    card_cta_text = '',
  } = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_invite_patients)) || {};

  useEffect(() => {
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD_2, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.ONBOARD_CUSTOMER,
    });
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(refreshMyProfile());
  }, []);

  const showCopiedToClipboard = () => {
    toastRef.current.show('Copied to clipboard.', 2000);
  };

  const copyReferralUrl = () => {
    const { messageTemplate: { greetingText = '', closingText = '' } = {} } = shareTemplate;
    AnalyticsManager.logEvent(EventType.INFLUENCER.DASHBOARD_INVITE, {
      handle,
    });
    const title = Config.APP_NAME;
    const url = `${isWeb() ? Config.WEB_URL : Config.URI_HOST}/@${handle}`;
    const message = `${greetingText} ${name}. ${closingText}`;
    if (isWeb()) {
      if (navigator.share) {
        navigator
          .share({
            title,
            text: message,
            url,
          })
          .then(() => console.log('Successful share! 🎉'))
          .catch((err) => console.error(err));
      } else {
        Clipboard.setString(`${Config.URI_HOST}/@${handle}`);
        showCopiedToClipboard();
      }
    }
    shareLink(title, message, url);
  };

  const shareLink = (title, message, url) => {
    Share.open({
      title,
      message: `${message} ${url}`,
    });
  };

  const onSubmit = () => {
    navigation.navigate('Influencer', {
      bioHandle: handle,
      previousScreen: 'OnboardCustomers',
    });
  };

  const copyOneLink = () => {
    Clipboard.setString(`${Config.URI_HOST}/@${handle}`);
    showCopiedToClipboard();
  };
  const trimmedHandle = `${handle}${handle.length > 20 ? '...' : ''}`;
  return (
    <View style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer}>
          <DesktopHeaderLogo />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle} />}
        {!isDesktop() && <StaticNavigationHeader title={headerTitle} subtitle={headerSubtitle} />}
        <Text style={currStyles.title}>{`Your Page is Ready, ${name}`}</Text>
        <View style={styles.copyHandleContainer}>
          <Text
            style={styles.registrationCompletedSubheading}
            numberOfLines={1}
          >{` ${Config.URI_HOST}/@${trimmedHandle}`}</Text>
          <TouchableOpacity onPress={copyOneLink} style={styles.copyTextContainer}>
            <FastImageView
              source={getMinifiedImage(images.copy_blue, styles.copyImage.width)}
              style={styles.copyImage}
            />
            <Text style={styles.copyText}>Copy</Text>
          </TouchableOpacity>
        </View>
        <ScrollView style={styles.scrollView} scrollEnabled={false}>
          <View style={styles.fullWidthContainer}>
            <View dataSet={{ media: ids.formContainer }} style={styles.mediaContainer}>
              <View style={currStyles.container}>
                <View style={currStyles.timeline} />
                <View>
                  <View style={currStyles.dot} />
                  <Text style={currStyles.text}>{invite_patients_card_content[0]}</Text>
                </View>
                <View>
                  <View style={currStyles.dot} />
                  <Text style={currStyles.text}>{invite_patients_card_content[1]}</Text>
                </View>
                <View>
                  <View style={currStyles.outerDot} />
                  <View style={currStyles.dot} />
                  <Text style={[currStyles.text, currStyles.lastText]}>{invite_patients_card_content[2]}</Text>
                </View>
              </View>
              <View style={currStyles.bottomContainer}>
                <TouchableOpacity onPress={copyReferralUrl} style={currStyles.editProfileButtonContainer}>
                  <FastImageView source={images.inviteIcon.uri} style={styles.editButtonIcon} />
                  <Text style={styles.editButtonText}>{card_cta_text}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </ScrollView>
        <SaveProfileButton buttonText={cta_text} onPress={onSubmit} screenName={SCREEN_CONSTANTS.ONBOARD_CUSTOMER} />
      </View>
      <Toast style={currStyles.toast} ref={toastRef} />
    </View>
  );
}


const { ids: currIds, styles: currStyles } = StyleSheet.create({
  container: {
    backgroundColor: '#FFE7E8',
    borderRadius: 16,
    marginHorizontal: 12,
    paddingTop: 20,
    paddingHorizontal: 12,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  bottomContainer: {
    backgroundColor: '#FFE7E8',
    borderRadius: 16,
    marginHorizontal: 12,
    paddingRight: 20,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  timeline: {
    position: 'absolute',
    borderLeftWidth: 1.5,
    borderStyle: 'dotted',
    top: 27,
    bottom: 22,
    alignSelf: 'flex-start',
    left: 12,
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.foxyBlack,
    marginBottom: 30,
    marginLeft: 14,
  },
  dot: {
    position: 'absolute',
    height: 8,
    width: 8,
    backgroundColor: colors.black,
    left: -3,
    top: 4,
    borderRadius: 4,
    '@media (min-width: 768px)': {
      borderRadius: '50%',
    },
  },
  lastText: {
    marginBottom: 0,
  },
  outerDot: {
    position: 'absolute',
    height: 14,
    width: 14,
    backgroundColor: '#FFE7E8',
    left: -5.7,
    top: 1,
    borderRadius: 8,
    '@media (min-width: 768px)': {
      borderRadius: '50%',
    },
    borderWidth: 1,
    borderColor: colors.black,
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 22,
    color: colors.black,
    marginTop: 20,
    alignSelf: 'center',
  },
  toast: { position: 'absolute', bottom: 20 },
  editProfileButtonContainer: {
    backgroundColor: colors.black,
    borderRadius: 4,
    paddingVertical: 12,
    marginHorizontal: 24,
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'center',
    width: '90%',
    alignItems: 'center',
    marginBottom: 12,
  },
});
