export const ShowSmartBanner = () => {
  var banner = document.querySelector('.js_smartbanner');
  if (banner) {
    banner.style.setProperty('display', 'block', 'important');
    document.getElementById('root').style.height = 'calc(100% - 84px)';
    var html = document.querySelector('html');
    html.style.setProperty('margin-top', '84px', 'important');
  }
}

export const HideSmartBanner = () => {
  var banner = document.querySelector('.js_smartbanner');
  if (banner) {
    banner.style.setProperty('display', 'none', 'important');
    document.getElementById('root').style.height = '100%';
    var html = document.querySelector('html');
    html.style.setProperty('margin-top', '0', 'important');
  }
}