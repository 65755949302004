import Config from '../libraries/ReactNativeConfig';
import NavigationService from '../navigator/NavigationService';
import DynamicLinkManager from './DynamicLinkManager';
import { isIOS } from './BooleanUtility';
import DynamicLinkUtilities from './DynamicLinkUtilities';

export const HandleNavigationFromWebview = (clickParams, currentUrl) => {
  const { url = '', navigationType } = clickParams;

  const { slug: currentSlug, route: currentRoute } =
    getRouteAndSlugFromUrl(currentUrl);
  const { slug, route } = getRouteAndSlugFromUrl(url);
  const isExternalLink = isIOS() ? navigationType === 'click' : true;
  if (isExternalLink && route === currentRoute && currentSlug === slug)
    return false;

  const isProductClick =
    url.startsWith(`${Config.WEB_URL}/products/`) ||
    url.startsWith(`${Config.HTTPS_WWW_WEB_URL}/products/`);
  const isCollectionClick =
    url.startsWith(`${Config.WEB_URL}/collections/`) ||
    url.startsWith(`${Config.HTTPS_WWW_WEB_URL}/collections/`);
  if (isExternalLink && (isProductClick || isCollectionClick)) {
    navigate(url);
    return false;
  }
  return !isExternalLink;
};

const handleRouteFromLink = ({ route = '', slug = '', extra = {} }) => {
  if (route === 'GoBack') {
    NavigationService.goBack();
    return;
  }
  NavigationService.push(route, { slug, extra, destination: slug });
};

const navigate = (url) => {
  DynamicLinkManager.handleLinkWithInternalTrackingParams(
    url,
    handleRouteFromLink,
  );
};

const getRouteAndSlugFromUrl = (url) => {
  const [slug, params] = DynamicLinkUtilities.removeDomainName(url);
  const route = DynamicLinkUtilities.extractRouteFromSlug(slug, url);
  return { slug, route };
};
