import React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity } from 'react-native';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import DynamicLinkUtilities from '../../utils/DynamicLinkUtilities';
import {
  AnalyticsEvent,
  AnalyticsManager,
  AnalyticsUtilty,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isWeb, isPresent } from '../../utils/BooleanUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { SEP } from '../../config/Constants';

const getNavigationSource = (categoryName, attributeName) => (
  { source: `product_attribute_pill${SEP}${categoryName}${SEP}${attributeName}`}
);

const navigateToCategory = (
  slug = '',
  navigation,
  attributeName,
  category_name,
  attributeValue,
) => {
  navigateToScreen({
    navigation,
    type: 'push',
    screen: SCREEN_CONSTANTS.CATEGORY,
    navigationSource: getNavigationSource(category_name, attributeName),
    params: {
      slug: `/${slug}`,
      property: attributeName !== category_name ? attributeValue : '',
      propertyName: attributeName,
    },
  });
};
const navigateToMorePage = (
  slug = '',
  navigation,
  attributeName,
  category_name,
  attributeValue,
) => {
  navigateToScreen({
    navigation,
    type: 'push',
    screen: 'MoreItems',
    navigationSource: getNavigationSource(category_name, attributeName),
    params: {
      slug: `/${slug}`,
      property: attributeName !== category_name ? attributeValue : '',
      previousScreen: 'productAttributePill',
    },
  });
};

const navigateToBrand = (slug = '', navigation, categoryName = '', attributeName = '') => {
  navigateToScreen({
    navigation,
    type: 'push',
    screen: 'Brand',
    navigationSource: getNavigationSource(categoryName, attributeName),
    params: {
      slug,
    },
  });
};

const ProductAttributesPill = ({
  attributeName = '',
  slug,
  navigation,
  type,
  id,
  sku_id,
  category_name,
  value = '',
}) => {
  const attributeValue = isPresent(value) ? value : attributeName;
  const handleNavigation = () => {
    if (type === 'brand') {
      AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
        [EventParameterKey.SKU_ID]: sku_id,
        [EventParameterKey.PRODUCT_ID]: id,
        [EventParameterKey.ELEMENT_NAME]: type,
        [EventParameterKey.ELEMENT_TYPE]: 'tags',
      });
      navigateToBrand(slug, navigation, attributeValue, category_name, attributeName);
    } else if (type === 'product_category' || (isWeb() && slug.includes('categories/'))) {
      AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
        [EventParameterKey.SKU_ID]: sku_id,
        [EventParameterKey.PRODUCT_ID]: id,
        [EventParameterKey.ELEMENT_NAME]: type,
        [EventParameterKey.ELEMENT_TYPE]: 'tags',
      });
      navigateToCategory(slug, navigation, attributeName, category_name, attributeValue);
    } else {
      AnalyticsManager.logEvent(EventType.product.PRODUCT_ELEMENT_CLICK, {
        [EventParameterKey.SKU_ID]: sku_id,
        [EventParameterKey.PRODUCT_ID]: id,
        [EventParameterKey.ELEMENT_NAME]: attributeValue,
        [EventParameterKey.ELEMENT_TYPE]: 'tags',
      });

      navigateToMorePage(slug, navigation, attributeName, category_name, attributeValue);
    }
  };

  if (Utility.isBlank(attributeName)) {
    return null;
  }

  return (
    <TouchableOpacity style={styles.container} onPress={handleNavigation}>
      <Text style={styles.pillText}>{attributeName}</Text>
      <Image source={images.chevronRight} style={styles.chevronImage} />
    </TouchableOpacity>
  );
};

export default withProfiledNavigation(ProductAttributesPill);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 12,
    paddingVertical: 6,
    flexDirection: 'row',
    marginRight: 8,
    marginBottom: 8,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pillText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: '#173143',
  },
  chevronImage: {
    height: 8,
    width: 5,
    tintColor: colors.foxyBlack,
    marginLeft: 4,
  },
});
