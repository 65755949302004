// Dependencies
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  View,
  ImageBackground,
  Text,
  Animated,
  findNodeHandle,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  BackHandler,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import _ from 'lodash';
import Toast from 'react-native-easy-toast';
import {
  fetchRelatedContent,
  getArtistContent,
  fetchMorePageListLatest,
  unLike,
} from '../../actions/ActionTypes';
import styles from './styles';
import { NavigationBackHeader } from '../../components/header';
import {
  LAYOUT,
  MAX_LINES,
  ARTIST_CONSTANTS,
  REMOTE_CONFIG_KEYS,
  PAGINATION_API_LIMIT,
  SHOPIFY_COLLECTIONS_SLUG,
  COLLECTIONS_SLUG,
  LISTS_API_VERSION,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
} from '../../config/Constants';
import { withMaybe } from '../../lib/Monads';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { CustomNavigationHeader } from '../../components/Product';
import DockedHeader from '../../utils/DockedHeader';
import {
  Pager,
  Grid,
  Listing,
  ReviewGrid,
  GridRail,
  Rail,
  Vertical,
} from '../../components/layout';
import GridScreenPlaceHolder from '../../components/shared/GridScreenPlaceHolder';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import colors from '../../theme/Colors';
import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
  EventParameterValue,
} from '../../analytics';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import PersonalisedPage from './PersonalisedPage';
import FullBanner from '../../components/banner/FullBanner';
import FilterResultsEmptyState from '../../components/sort-filter-options/FilterResultsEmptyState';
import RemoteConfig from '../../utils/RemoteConfig';
import FoxyPersonalisedItems from '../FoxyPersonalisedItems';
import Images from '../../theme/Images';
import { List } from '../List';
import ListWithFilterComponent from '../List/ListWithFilterComponent';
import FilterUtils, {
  canOpenSortingModal,
} from '../../components/sort-filter-options/FilterUtils';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { appendAttributesWithHomePageUrl } from '../../utils/UriUtility';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import { isDesktop, isPresent, isWeb, isBlank, isNative } from '../../utils/BooleanUtility';
import { filteredRequiredAttribute } from '../../utils/UriUtility';
import { getFullSlugFromName } from '../../utils/StringUtility';
import Config from '../../libraries/ReactNativeConfig';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class MoreItemsList extends Component {
  Components = {
    rail: Rail,
    gridRail: GridRail,
    list: Listing,
    grid: Grid,
    vertical: Vertical,
    rectangularGrid: Grid,
    reviewGrid: ReviewGrid,
    personalizedVertical: PersonalisedPage,
    fullPageBanner: FullBanner,
  };

  constructor(props) {
    super(props);
    Utility.setPageLoadStart('more_items');
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.MORE_PAGE);
    this.trace.start();
    const { route } = props;
    const {
      route: {
        params: { brandId = '', categoryIds = [], previousScreen = '' } = {},
      } = {},
    } = this.props;
    this.extractedFiltersFromDeeplink = [];
    let existingSelectedFilters = {};

    let dynamicUrl = route.params?.url;
    if (Utility.isBlank(dynamicUrl) && Utility.isIOS()) {
      dynamicUrl = route.params?.destination;
    }

    if (Utility.isPresent(dynamicUrl)) {
      this.extractedFiltersFromDeeplink =
        Utility.extractSelectedFiltersFromDeeplink(dynamicUrl, false);

      if (Utility.isPresent(this.extractedFiltersFromDeeplink)) {
        existingSelectedFilters = _.groupBy(
          this.extractedFiltersFromDeeplink,
          (element) => element.criteria_name,
        );
      }
    }

    this.preventListEmptyStateOnInitialPageLoad = true;
    this.bottomShimmerColumnCount = 2;
    this.state = {
      scrollY: new Animated.Value(0),
      offerApplied: '',
      isCartVisible: false,
      viewRef: null,
      itemData: props.itemData || {},
      selectedFilters: existingSelectedFilters,
      apiLoading: false,
      showLoadMoreShimmerAtListBottom: false,
      showSearchFooter: false,
      isPageLoading: true,
      stickyPosition: -1,
      showProductShimmer: false,
      filters: [],
      quickFilters: [],
      filteredSlug: '',
      showGridShimmer: true,
      editList: false,
      editableList: [],
      serverError: false,
      pageNotFound: false,
      hideOosProduct: false,
      refreshing: true,
      filtersList: {},
    };

    this.list = route.params?.listData;
    this.item = route.params?.item ?? {};
    this.property = route.params?.property;
    this.uaavs = route.params?.uaavs;
    this.fromPersonalizedPage = route.params?.fromPersonalisedCard ?? false;
    this.bannerImageUrl = route.params?.filteredImage;
    if (!Utility.isBlank(this.item)) {
      this.display =
        this.item.display === LAYOUT.LIST ||
        this.item.display === 'review-grid' ||
        this.item.display === 'rectangular-grid' ||
        this.item.display === 'rail' ||
        this.item.display === 'grid-rail' ||
        this.item.display === 'vertical'
          ? _.camelCase(this.item.display)
          : LAYOUT.GRID;

      if (!Utility.isBlank(this.item.more_page_display)) {
        this.display =
          this.item.more_page_display === 'personalized-vertical' ||
          this.item.more_page_display === 'rectangular-grid' ||
          this.item.more_page_display === 'grid' ||
          this.item.display === 'grid-rail'
            ? _.camelCase(this.item.more_page_display)
            : this.item.more_page_display;
      }

      this.LayoutComponent = this.Components[this.display];
    }
    this.AnimatedText = Animated.createAnimatedComponent(Text);
    this.BannerImage = Animated.createAnimatedComponent(ImageBackground);
    this.previousScreen = route.params?.previousScreen ?? '';
    this.listIndex = route.params?.index ?? '';
    const { itemData } = this.props;
    if (Utility.isPresent(itemData)) {
      const analyticsMeta = {
        [EventParameterKey.SCREEN_NAME]: this.previousScreen,
        [EventParameterKey.LIST_POSITION]: this.listIndex || 0,
        [EventParameterKey.LIST_ID]: itemData.id,
        [EventParameterKey.LIST_NAME]: itemData.name,
        [EventParameterKey.LIST_DISPLAY]: itemData.display,
        [EventParameterKey.LIST_CONTENT]: itemData.content,
      };
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.LIST_DETAIL_VIEW,
        analyticsMeta,
      );

      AnalyticsManager.logEvent('list_rendered', {
        id: itemData.id,
      });
    }

    this.pageNo = 0;
    this.pageResultItemsCount = 20;
    this.allowNextPageLoad = true;
    this.search = route.params?.search;
    this.slug = this.getRouteSlug();
    this.item = route?.params?.item;
    this.requiredPersonalizedAttribute =
      route?.params?.item?.required_user_attributes;
    if (props.slug) {
      this.slug = `${props.slug}&Nan=Nan`;
      this.slug = this.slug.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
    } else if (this.search) {
      this.slug = `${this.slug}&Nan=Nan`;
      this.slug = this.slug?.replace('v0', 'v2');
    } else if (previousScreen === 'productAttributePill') {
      this.slug = `${this.slug}/all-products?Nan=Nan`;
      this.slug = this.slug.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
    } else {
      this.slug = `${this.slug}?Nan=Nan`;
      this.slug = this.slug.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
    }

    if (this.slug.includes(COLLECTIONS_SLUG)) {
      this.slug = this.slug.replace(COLLECTIONS_SLUG, SHOPIFY_COLLECTIONS_SLUG);
    }

    this.indexOfFoxyMatchFilterInUrl = _.findIndex(
      this.extractedFiltersFromDeeplink,
      (e) => e?.criteria_name === 'foxy_match',
    );

    this.isFoxyMatchFilterEnableInUrl = this.indexOfFoxyMatchFilterInUrl !== -1;

    if (!this.isFoxyMatchFilterEnableInUrl) {
      if (Utility.isPresent(this.extractedFiltersFromDeeplink)) {
        this.slug = `${this.slug}&${Utility.extractSelectedFiltersFromDeeplink(
          route.params?.url,
          true,
        )}`;
      }
    }

    const extras = route.params?.extra;
    if (Utility.isPresent(extras)) {
      const { fromPersonalizedPage = false } = extras;
      this.addFacialAttributesInApiRequest = fromPersonalizedPage;
    }

    this.updateQuickFilterNameOnSortingFilterApplied = null;
    this.onEndReachedCalledDuringMomentum = true;
    this.selectedQuickFilters = [];
    this.quickFiltersRef = null;
    this.scrollViewRef = null;
    this.debounceEndReached = _.debounce(this.onFlatListEndReached, 500);
    this.filtersEnabled = false;
    this.flatListRef = null;
    this.prevAppliedFiltersFromModal = [];
    this.preventPaginationOnFirstTimeQuickFtilersApply = false;
    this.counterFilterPress = -1;
    this.fnAutoListPagination = null;

    if (Utility.isPresent(this.property)) {
      this.slug += `&property_values[]=${this.property}`;
    }

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
    this.forceInset = { top: 'never', bottom: 'always' };
    this.brandId = brandId;
    this.categoryIds = categoryIds;
    this.resetFilter = false;
    this.navigationSource = getNavigationSource(this, this.props);
  }

  componentDidUpdate(prevProps, prevState) {
    const { editList, itemData = {} } = this.state;
    if (editList !== prevState.editList) {
      this.setState({
        editableList: itemData.objects,
      });
    }

    if (
      JSON.stringify(prevState.itemData) !==
      JSON.stringify(prevState?.itemData || {})
    ) {
      this.setState({
        editableList: itemData.objects,
      });
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  toggleCartVisibility = () => {
    this.props.navigation.push(this.navigationSource, 'Cart', {
      source: 'more_items',
    });
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  componentDidMount() {
    const { navigation, selfieImageUrl, getArtistContent, facialProperties } =
      this.props;
    this.timer = null;

    if (Utility.isPresent(this.uaavs)) {
      this.slug += this.uaavs;
    }
    if (this.previousScreen === 'dashboard') {
      getArtistContent(ARTIST_CONSTANTS.artistContentSlug);
      return;
    }
    if (this.fromPersonalizedPage && Utility.isPresent(selfieImageUrl)) {
      this.slug = appendAttributesWithHomePageUrl(
        this.slug,
        facialProperties,
        false,
      );
      // return;
    }
    const paginationCount = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.pagination_objects_count,
    );
    this.pageResultItemsCount = paginationCount;

    if (this.slug?.indexOf('fromPersonalizedPage=true') !== -1) {
      this.slug = this.slug?.replace('fromPersonalizedPage=true', '');
    }

    this.hitMorePageApi();
  }

  toggleEditList = () => {
    AnalyticsManager.logEvent(EventType.profileEvents.EDIT_WISHLIST);
    this.setState({ editList: true });
  };

  toggleCartVisibility = () => {
    const { navigation } = this.props;
    navigation.push(this.navigationSource, 'Cart', {
      source: 'more_items',
    });
  };

  isSearchEnabled = () => this.pageNo === 0 && this.filtersEnabled;

  setTimeOutCallback = () => {
    const offsetNumber = Utility.isAndroid() ? 0 : 0;
    const scroll = 0.67 * Utility.getScreenWidth() - offsetNumber;
    // this.flatListRef.scrollToOffset({ animated: true, offset: scroll });
    this.onEndReachedCalledDuringMomentum = true;
  };

   onHardwareBackKeyPress = () => {
    this.goBack();
    return true;
  };

  hitMorePageApi = (hideOosProduct = this.state.hideOosProduct) => {
    const { fetchMorePageListLatest, route } = this.props;
    const { itemData } = this.state;
    const { args = [] } = route.params || {};

    if (Utility.isBlank(this.getRouteSlug() || this.props.slug)) {
      return;
    }
    if (this.pageNo === 0 && this.filtersEnabled) {
      setTimeout(this.setTimeOutCallback, 500);
    }
    this.setState({
      showLoadMoreShimmerAtListBottom: !this.isSearchEnabled(),
    });

    // Right now showing shimmer only on filter applied
    //   if (this.pageNo === 0 && Utility.isPresent(itemData)) {
    // itemData.objects = [];
    this.setState({
      // itemData,
      apiLoading: true,
    });

    const isPersonalizedUrl =
      this.slug.includes('personalized') &&
      isBlank(this.requiredPersonalizedAttribute);
    if (isPresent(this.requiredPersonalizedAttribute) || isPersonalizedUrl) {
      this.slug = filteredRequiredAttribute(
        this.slug,
        this.requiredPersonalizedAttribute,
        isPersonalizedUrl,
      );
    }
    AnalyticsUtility.recordTimeWithFilterCounter(
      {
        screen_name: SCREEN_CONSTANTS.MORE_PAGE,
        ...Utility.setRecordTimeEventMeta(itemData, 'list'),
        page_number: this.pageNo,
      },
      this.morePageCallback,
      fetchMorePageListLatest,
      this.slug,
      this.pageNo,
      hideOosProduct,
      this.addFacialAttributesInApiRequest,
      this.counterFilterPress,
      args,
    );
  };

  autoHitApiCall = () => {
    if (!this.allowNextPageLoad) {
      return;
    }
    setTimeout(() => {
      this.onFlatListEndReached();
    }, 1000);
  };

  morePageCallback = (success, data, pageNo, counterFilterPress, status) => {
    const {
      itemData = {},
      showGridShimmer,
      serverError,
      pageNotFound,
      refreshing,
    } = this.state;
    if (!this.traceStopped) {
      this.trace.stop();
      this.traceStopped = true;
    }
    if (showGridShimmer) {
      this.setState({ showGridShimmer: false });
    }
    if (!success) {
      Utility.clearPageLoadTimer();
      const showServerError = data?.status !== 404 && data?.status !== 500;
      this.setState({
        showLoadMoreShimmerAtListBottom: false,
        apiLoading: false,
        showSearchFooter: true,
        serverError: showServerError,
        pageNotFound: !showServerError,
        refreshing: false,
      });
      return;
    }
    Utility.setPageLoadEnd('more_items', pageNo);

    if (
      counterFilterPress !== -1 &&
      this.counterFilterPress !== counterFilterPress
    ) {
      return;
    }
    if (pageNo === 0) {
      if (isWeb()) {
        const { id, content, name, display } = data || {};
        AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_VIEW, {
          [EventParameterKey.SCREEN_NAME]: location.pathname.split('/')[1],
          [EventParameterKey.LIST_ID]: id,
          [EventParameterKey.LIST_TYPE]: content,
          [EventParameterKey.LIST_NAME]: name,
          [EventParameterKey.LIST_POSITION]: 0,
          listDisplay: display,
        });
      }
      let responseData = {};

      if (
        (this.filtersEnabled || this.resetFilter) &&
        itemData.display === 'vertical' &&
        !refreshing
      ) {
        // FIXME: Hacky Fix: on clicking reset, the parent vertical list is replaced by the last product list in the parent objects.
        const listData = FilterUtils.appendFilteredListToMasterListObjects(
          this.slug,
          data,
          itemData,
        );
        this.resetFilter = false;
        this.setState({
          itemData: { ...listData },
          apiLoading: false,
        });

        //   this.setState({ itemData: { ...listData } }, () =>
        //   this.fnAutoListPagination(this.slug, -1, true),
        // );
      } else if (data.display === 'vertical') {
        this.addFiltersObjectIntoListData(data);
      } else {
        responseData = data;

        if (Utility.isPresent(this.property)) {
          if (Utility.isBlank(data.quick_filters)) {
            responseData.quick_filters = [];
          }
          responseData.quick_filters = [
            Utility.createPreSelectedFilter(this.property),
            ...data.quick_filters,
          ];
          responseData.quick_filters = _.uniqBy(data.quick_filters, 'name');
        }

        this.setState(
          {
            itemData: responseData,
            showProductShimmer: false,
            filters: responseData.filters,
            quickFilters: responseData.quick_filters,
            apiLoading: false,
          },
          this.paginateApi,
          this.autoApplyFoxyMatchFilters,
        );
      }
      this.setState({
        refreshing: false,
      });
    } else {
      const { itemData } = this.state;
      let items = {};
      if (itemData.display === 'grid') {
        this.allowNextPageLoad =
          data.objects.length >= this.pageResultItemsCount;

        items = this.appendItemsIntoGrid(data);

        this.setState(
          {
            itemData: items,
            apiLoading: false,
            refreshing: false,
          },
          this.paginateApi,
        );
      }
      if (itemData.display === 'list') {
        this.allowNextPageLoad =
          data.objects.length >= this.pageResultItemsCount;

        items = this.appendItemsIntoGrid(data);

        this.setState(
          {
            itemData: items,
            apiLoading: false,
            refreshing: false,
          },
          this.paginateApi,
        );
      } else {
        items = FilterUtils.appendFilteredListToMasterListObjects(
          this.slug,
          data,
          this.state.itemData,
        );
        this.allowNextPageLoad = isPresent(data.objects);

        this.setState({
          itemData: items,
          apiLoading: false,
          refreshing: false,
        });
      }
    }
  };

  paginateApi = () => {
    if (this.hasPaginationCallsExceeded()) return;
    this.autoHitApiCall();
  };

  autoApplyFoxyMatchFilters = () => {
    if (this.hasPaginationCallsExceeded()) return;
    const { filters } = this.state;
    const { facialProperties } = this.props;
    let foxyMatchAttributes = [];

    if (!this.isFoxyMatchFilterEnableInUrl) {
      return;
    }

    const indexOfFoxyMatch = _.findIndex(
      filters,
      (e) => e?.display === 'foxy_match',
    );

    if (indexOfFoxyMatch === -1) {
      return;
    }

    const foxyMatchFilter = filters[indexOfFoxyMatch];

    const commonUserAttribs = Utility.getCommonUserAttributes(
      facialProperties,
      foxyMatchFilter.criteria,
    );

    commonUserAttribs.forEach((element) => {
      element.values.forEach((elementValue) => {
        foxyMatchAttributes.push({
          attribute_id: element.id,
          value: elementValue.id,
        });
      });
    });

    const foxyMatchFilterObject = {
      criteria_name: 'user_attribute_allowed_value_id',
      value: foxyMatchAttributes,
    };
    setTimeout(() => {
      this.onFilterPress([foxyMatchFilterObject], true, 0);
    }, 250);

    this.isFoxyMatchFilterEnableInUrl = false;
  };

  hasPaginationCallsExceeded = () => {
    return (this.pageNo + 1) % PAGINATION_API_LIMIT === 0;
  };

  addFiltersObjectIntoListData = (apiResponse) => {
    const response = apiResponse;

    const {
      list: objects,
      position: stickyPosition,
      slug: listSlug,
    } = Utility.createFilterObjectIfExists(response.objects, true, false, true);
    response.objects = objects;
    const { filters, quickFilters } = FilterUtils.getFiltersFromList(response);

    this.slug = listSlug;
    response.objects = this.addPreselectedFilterToVerticalList(objects);

    this.setState(
      {
        itemData: response,
        filters,
        quickFilters,
        apiLoading: false,
        stickyPosition,
      },
      () => {
        if (Utility.isPresent(this.state.quickFilters)) {
          this.autoApplyPreSelectedFilter();
        }
      },
    );
  };

  autoApplyPreSelectedFilter = () => {
    if (Utility.isBlank(this.property)) return;
    setTimeout(() => {
      this.onFilterPress(
        Utility.createPreSelectedFilter(this.property),
        true,
        0,
      );
    }, 200);
  };

  addPreselectedFilterToVerticalList = (listObjects) => {
    const indexOfFilters = _.findIndex(
      listObjects,
      (e) => e.type === 'quick_filters',
    );

    const filterObject = listObjects[indexOfFilters];

    if (
      Utility.isPresent(this.property) &&
      filterObject &&
      filterObject.quick_filters
    ) {
      filterObject.quick_filters = [
        Utility.createPreSelectedFilter(this.property),
        ...filterObject.quick_filters,
      ];

      filterObject.quick_filters = _.uniqBy(filterObject.quick_filters, 'name');

      const listObjectsClone = listObjects;
      listObjectsClone[indexOfFilters] = filterObject;
      return listObjectsClone;
    }
    return listObjects;
  };

  appendItemsIntoGrid = (data) => {
    const {
      itemData: { objects: existingObjects },
      itemData,
    } = this.state;

    const { objects: currentObjects } = data;

    const objects = [...existingObjects, ...currentObjects];
    itemData.objects = objects;
    return itemData;
  };

  toggleCartVisibility = () => {
    this.setState((prevState) => ({ isCartVisible: !prevState.isCartVisible }));
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false });
  };

  goBack = () => {
    const { navigation } = this.props;
    if (isNative() && navigation.getState().routes.length < 2) {
      navigation.replace('TabNavigator');
      return;
    }
    if (!navigation.canGoBack()) {
      window.history.back();
    }
    navigation.goBack();
  };

  listConditionFn = (props) =>
    props.content === 'list' && this.previousScreen !== SCREEN_CONSTANTS.ARTIST;

  customNavigationHeader = withMaybe(this.listConditionFn)(
    CustomNavigationHeader,
  );

  conditionalBanner = withMaybe(this.listConditionFn)(View);

  dockedHeader = (props) => {
    const { name } = props;
    return (
      <View style={styles.dockedHeaderContainer}>
        <Text
          numberOfLines={1}
          style={styles.productNameInHeaderStyle}
          allowFontScaling={false}
        >
          {name}
        </Text>
      </View>
    );
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  updateSelectedOffer = (id) => {
    this.setState((prevState) => {
      const newId = prevState.offerApplied !== id ? id : '';
      return { offerApplied: newId };
    });
  };

  imageLoaded = () => {
    this.setState({ viewRef: findNodeHandle(this.backgroundImage) });
  };

  banner = (props) => {
    let { banner_image_url, content, item, previousScreen } = props;

    if (this.fromPersonalizedPage) {
      return null;
    }

    if (this.item.more_page_display === 'full-page-banner') {
      return null;
    }

    return (
      <View content={content} style={styles.bannerStyle}>
        <Animated.Image // banner background
          useNativeDriver
          ref={(img) => {
            this.backgroundImage = img;
          }}
          source={{
            uri: getMinifiedImage(
              banner_image_url,
              Utility.getScreenWidth(),
              0.67 * Utility.getScreenWidth(),
            ),
          }}
          style={[
            styles.bannerImageStyle,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
          onLoadEnd={this.imageLoaded}
        />
        {/* <AnimatedBlurView // blurview
          style={[
            styles.bannerImageStyle,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
          viewRef={this.state.viewRef}
          blurType='light'
          blurAmount={10}
        /> */}
        <Animated.Image // banner image
          useNativeDriver
          ref={(img) => {
            this.backgroundImage = img;
          }}
          source={{
            uri: getMinifiedImage(
              banner_image_url,
              Utility.getScreenWidth(),
              0.67 * Utility.getScreenWidth(),
            ),
          }}
          style={[
            styles.bannerImageStyle,
            {
              resizeMode: 'contain',
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.67 * Utility.getScreenWidth() + 200,
                  0.67 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
            },
          ]}
        />
        <Animated.View // Overlay
          style={[
            styles.imageOverlay,
            {
              height: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  0.75 * Utility.getScreenWidth() + 200,
                  0.75 * Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              width: this.state.scrollY.interpolate({
                inputRange: [-200, 0],
                outputRange: [
                  Utility.getScreenWidth() + 200,
                  Utility.getScreenWidth(),
                ],
                extrapolate: 'clamp',
              }),
              useNativeDriver: true,
            },
          ]}
        />
        <View style={styles.infoContainer}>
          <Text numberOfLines={MAX_LINES.two} style={styles.listHeading}>
            {item.name}
          </Text>
        </View>
      </View>
    );
  };

  forceUpdatePersonalisedPageAfterSelfie = () => {
    this.addFacialAttributesInApiRequest = true;
    this.hitMorePageApi();
  };

  resetSlug = () => {
    const { itemData } = this.state;

    if (itemData.display === 'vertical') {
      const objectsCount = itemData?.objects.length;
      this.slug = itemData?.objects[objectsCount - 1].slug;
    } else {
      this.slug = `${itemData.slug}?Nan=Nan`;
    }
  };

  onRefresh = () => {
    const { itemData: { slug = '' } = '' } = this.state;
    isPresent(this.quickFiltersRef) && this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
      refreshing: true,
    });
    this.slug = `${slug}?Nan=Nan`;
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.selectedQuickFilters = [];
    this.hitMorePageApi();
    this.filtersEnabled = false;
  };

  resetFilters = () => {
    this.pageNo = 0;
    this.allowNextPageLoad = true;

    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.resetSlug();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.selectedQuickFilters = [];

    this.hitMorePageApi();
  };

  listEmptyState = () => {
    const { itemData } = this.state;
    if (Utility.isPresent(itemData.objects)) return null;

    if (this.preventListEmptyStateOnInitialPageLoad) return null;

    return (
      <View>
        <FilterResultsEmptyState onPressResetButton={this.resetFilters} />
      </View>
    );
  };

  onSortingFilterApplied = (updateSortingQuickFilter) => {
    this.updateQuickFilterNameOnSortingFilterApplied = updateSortingQuickFilter;
  };

  onMomentumScrollBegin = () => {
    this.onEndReachedCalledDuringMomentum = false;
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  onFlatListEndReached = () => {
    if (!this.allowNextPageLoad) {
      return;
    }

    if (this.addFacialAttributesInApiRequest) {
      return;
    }

    this.pageNo += 1;
    this.hitMorePageApi();
    this.onEndReachedCalledDuringMomentum = true;
  };

  flatListHeaderViews = (props) => {
    const {
      banner_image_url,
      content,
      image_url,
      offers,
      request_personalized_details = true,
    } = this.item || {};
    if (content !== 'product') {
      return null;
    }

    if (!request_personalized_details) {
      return null;
    }

    const { forceShowBanner } = props;
    const { offerApplied } = this.state;
    const hideBanners = Utility.isBlank(this.item.objects) && !forceShowBanner;
    return (
      <>
        <FoxyPersonalisedItems
          slug={this.slug}
          subheading='Products in this list which you shouldn’t miss'
        />
      </>
    );
  };

  renderSearchFooter = () => {
    const { navigation } = this.props;
    const navigateToSearch = () => navigation.push('Search', {});
    return (
      <View style={styles.feedFooterContainer}>
        <Text style={styles.footerTextHeading}>
          Didn’t find what you were looking for ?
        </Text>
        <TouchableOpacity onPress={navigateToSearch}>
          <View style={styles.footerTextAndImageContainer}>
            <Text style={styles.footerButtonText}>Search</Text>
            <Image
              style={styles.footerSearchImage}
              source={Images.searchIic_search_productcon}
            />
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  renderActivityIndicator = () => (
    <ActivityIndicator
      size='large'
      style={styles.activityIndicatorContainer}
      color={colors.green}
    />
  );

  listKeyExtractor = (item, index) => `${item.length}_${index}_more`;

  listFooterComponent = () => {
    const { showLoadMoreShimmerAtListBottom, itemData, showSearchFooter } =
      this.state;
    const itemDataObjectCount =
      Utility.isPresent(itemData) && itemData.objects
        ? itemData.objects.length
        : 0;
    const minHeight = 110;
    const maxHeight = Utility.isAndroid() ? 450 : 400;
    const height = itemDataObjectCount >= 4 ? minHeight : maxHeight;

    if (showLoadMoreShimmerAtListBottom) {
      return (
        <ActivityIndicator
          size='large'
          style={styles.activityIndicatorContainer}
          color={colors.green}
        />
      );
    }

    if (!showLoadMoreShimmerAtListBottom) {
      return <View style={{ height: 12 }} />;
    }
    // return <View style={{ height: 60 }} />;
  };

  onListStartScroll = (event) => {
    this.onFlatListEndReached();
  };

  onEndReached = () => {
    const { apiLoading } = this.state;
    if (apiLoading) {
      this.pageNo += 1;
      this.hitMorePageApi();
    }
  };

  showEmptySearch = () => {
    const { navigation } = this.props;
    navigation.push('Search', {});
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  removeFromList = (item) => {
    const { unLike } = this.props;
    const { editableList } = this.state;
    const data = {
      id: item.id,
      type: item.type,
    };
    unLike(data);
    AnalyticsManager.logEvent(EventType.profileEvents.EDIT_WISHLIST, {
      [EventParameterKey.SOURCE]: EventParameterValue.SCREEN_NAME.PRODUCT_CARD,
      [EventParameterKey.ID]: item?.id || '',
    });
    const newList = _.filter(editableList, (e) => e.id !== item.id);
    this.setState({
      editableList: newList,
    });
  };

  setListPaginatedCallApiRef = (fnAutoListPagination) => {
    this.fnAutoListPagination = fnAutoListPagination;
  };

  onFilterPress = (
    item,
    added,
    index,
    resetQuickFilter,
    hideOosProduct = this.state.hideOosProduct,
  ) => {
    //remove property quick filter from itemData when de selected
    if (item.value === this.property && !added) {
      this.slug = this.slug.replace(`&property_values[]=${this.property}`, '');
      this.property = '';
    }

    const { navigation } = this.props;
    const {
      itemData = {},
      itemData: { id, name, slug: listSlug = '' } = {},
      selectedFilters,
      filters,
      scrollY,
    } = this.state;
    const { list } = FilterUtils.getList(itemData);
    this.setState({
      filtersList: list,
    });
    const shouldOpenSortingModal = canOpenSortingModal(item, selectedFilters);
    if (shouldOpenSortingModal) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        modalFilters: filters,
        onFilterApplyPress: this.onFilterPress,
        scrollY,
        listName: name,
        listId: id,
        listSlug,
        screenName: SCREEN_CONSTANTS.MORE_PAGE,
        entityName: name,
        listData: itemData,
        hideBrandFilter: Utility.isPresent(this.brandId),
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
        resetQuickFilter,
      });
      return;
    }

    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.MORE_PAGE,
      name,
      hideOosProduct,
    );

    this.slug = slug;
    this.resetFilter = Utility.isBlank(groupedSelectedFilters);
    this.pageNo = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.counterFilterPress += 1;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    if (itemData.display === 'vertical') {
      itemData.objects = Utility.addShimmerInListObject(this.state.itemData);

      this.setState({
        selectedFilters: groupedSelectedFilters,
        itemData: itemData,
        filteredSlug: this.slug,
      });
    } else {
      this.setState({
        selectedFilters: groupedSelectedFilters,
        showProductShimmer: true,
        filteredSlug: this.slug,
      });
    }
    this.hitMorePageApi(hideOosProduct);

    //Fetch List here.
  };

  onEndReached = () => {
    if (!this.allowNextPageLoad) {
      this.setState({
        apiLoading: false,
      });
      return;
    }
    this.onFlatListEndReached();
  };

  getRouteSlug = () => {
    const { route = {} } = this.props;
    const { params = {}, name } = route;
    const { slug, shortSlug } = params;
    if (slug) return slug;
    if (shortSlug) {
      return getFullSlugFromName(
        shortSlug,
        name === 'Ingredients' ? 'ingredients' : 'list',
      );
    }
    return '';
  };
  
  showShimmer = () => {
    const {
      itemData: { objects = [] } = {},
      serverError,
      pageNotFound,
    } = this.state;
    return (
      ((Utility.isBlank(objects) && this.fromPersonalizedPage) ||
        Utility.isBlank(this.item) ||
        this.state.showGridShimmer) &&
      !serverError &&
      !pageNotFound
    );
  };

  render() {
    const {
      selfieImageUrl,
      navigation,
      route,
      unLike,
      hideHeader = false,
    } = this.props;
    const {
      isPageLoading,
      itemData: { display, filters = EMPTY_ARRAY } = EMPTY_OBJECT,
      stickyPosition,
      selectedFilters,
      showProductShimmer,
      editableList,
      serverError,
      pageNotFound,
      apiLoading,
      filtersList = {},
    } = this.state;

    let { itemData: listData = EMPTY_OBJECT } = this.state;

    if (Utility.isPresent(this.state.itemData)) {
      this.item = this.state.itemData || EMPTY_OBJECT;
      this.display =
        this.item.display === LAYOUT.LIST ||
        this.item.display === 'review-grid' ||
        this.item.display === 'rectangular-grid' ||
        this.item.display === 'grid-rail' ||
        this.item.display === 'vertical'
          ? _.camelCase(this.display)
          : LAYOUT.GRID;
      if (!Utility.isBlank(this.item.more_page_display)) {
        this.display =
          this.item.more_page_display === 'personalized-vertical' ||
          this.item.more_page_display === 'rectangular-grid' ||
          this.item.display === 'grid-rail'
            ? _.camelCase(this.item.more_page_display)
            : this.item.more_page_display;
      }
    }

    // To display grid with filters

    if (this.display === 'grid') {
      this.display = 'FiltersEnabledGrid';

      if (
        listData.display === 'card' ||
        listData.display === 'vertical-rail' ||
        listData.display === 'rail' ||
        listData.display === 'grid-rail' ||
        listData.display === 'list'
      ) {
        listData.display = listData.more_page_display;
      }
    }

    let RootComponent = null;

    if (this.display === 'FiltersEnabledGrid') {
      RootComponent = ListWithFilterComponent;
    } else if (this.display === 'personalizedVertical') {
      RootComponent = PersonalisedPage;
    } else if (this.display === 'full-page-banner') {
      RootComponent = FullBanner;
    } else {
      RootComponent = List;
    }

    const {
      content,
      banner_image_url,
      slug,
      share_url: shareUrl,
      id,
      type,
      name,
      offers,
      image_url,
      more_page_display,
      objects = EMPTY_ARRAY,
      paginate_last_item,
      quick_filters = [],
    } = this.item || EMPTY_OBJECT;

    const {
      itemData: { objects: listObject = [] },
      editList,
      hideOosProduct,
    } = this.state;

    const { scrollY } = this.state;
    this.LayoutComponent = this.Components[this.display];
    const screenName = `${type} more`;

    const objectLength = objects.length - 1;

    this.bannerImageUrl = route.params?.filteredImage;

    const isListEditable =
      listData?.additional_data?.item_action?.primary === 'edit';

    const componentInteractive = !this.showShimmer();

    return (
      <ReactNavigationPerformanceView
        screenName={`more_list`}
        interactive={componentInteractive || isPresent(this.state.itemData)}
      >
        {this.showShimmer() && <GridScreenPlaceHolder fullscreen />}
        {!this.showShimmer() && (
          <>
            {!hideHeader && !isDesktop() && (
              <CustomNavigationHeader
                content={content}
                scrollY={scrollY}
                slug={slug}
                goBack={this.goBack}
                shareUrl={shareUrl}
                fromPersonalizedPage={this.fromPersonalizedPage}
                id={id}
                type={type}
                image={banner_image_url}
                name={name}
                previousScreen={this.previousScreen}
                morePage={isBlank(quick_filters)}
                screenName={SCREEN_CONSTANTS.MORE_PAGE}
                morePageDisplay={more_page_display}
                quickFiltersAvailable={isPresent(quick_filters)}
                showSearchButton
                onSearchIconTap={this.showEmptySearch}
                showEditButton={isListEditable}
                onEditTap={this.toggleEditList}
                utmCampaign={'list'}
              >
                <DockedHeader name={name} />
              </CustomNavigationHeader>
            )}

            <ErrorBoundary
              screen_name={screenName}
              itemData={listData}
              showServerError={serverError}
              pageNotFound={pageNotFound}
              hideHeader
            >
              <RootComponent
                listData={listData}
                itemData={listData}
                navigation={this.props.navigation}
                enableListingBackgroundColor
                previousScreen={SCREEN_CONSTANTS.MORE_PAGE}
                paginateLastItem={paginate_last_item}
                totalItemsInList={objectLength}
                getQuickFiltersListRef={this.getQuickFiltersListRef}
                stickyPosition={stickyPosition}
                selectedFilters={selectedFilters}
                onFiltersPress={this.onFilterPress}
                onPressResetButton={this.resetFilters}
                content={content}
                showProductShimmer={showProductShimmer}
                search
                setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
                filteredSlug={this.state.filteredSlug}
                verticalListHeader={this.flatListHeaderViews}
                bannerImageUrl={this.bannerImageUrl}
                unLike={unLike}
                showEditButton={editList}
                editableList={editableList}
                removeFromList={this.removeFromList}
                onEndReached={this.onEndReached}
                loadMore={apiLoading}
                hideOosProduct={hideOosProduct}
                onRefresh={this.onRefresh}
                filtersFromDeepLink={this.extractedFiltersFromDeeplink}
                webListTitle={name}
                filtersList={filtersList}
                useInViewPort
              />
            </ErrorBoundary>
            <Toast
              style={{ position: 'absolute', bottom: 20 }}
              ref={(ref) => {
                this.toast = ref;
              }}
            />
          </>
        )}
      </ReactNavigationPerformanceView>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { fetchRelatedContent, getArtistContent, fetchMorePageListLatest, unLike },
    dispatch,
  ),
});

const mapStateToProps = (store, ownProps) => ({
  selfieImageUrl: store.UserAccountInfo.profile.selfie_image_url,
  facialProperties: store.UserAccountInfo.facialAnalysis.facialProperties,
});
export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(MoreItemsList),
);

export const MoreItemsListHeaderOptions = ({ route }) => ({
  title: route.params?.title ?? 'More',
  headerShadowVisible: false,
  headerLeft: () => <NavigationBackHeader />,
});
