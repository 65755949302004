// Some Constants Are unwanted, need to removed them
export const SCREEN_CONSTANTS = {
  BRAND: 'brand',
  INTEREST: 'interest',
  PROFILE_EDIT: 'profile_edit',
  MY_PROFILE: 'famous',
  UNCOMPLETED_PROFILE: 'uncompleted_profile',
  YOUTUBE: 'youtube',
  INSTAGRAM: 'instagram',
  CONSUMER_PROFILE: 'consumer_profile',
  TAKE_SELFIE: 'TakeSelfie',
  SELFIE_PROMPT: 'selfie_prompt',
  PERSONALISED_PAGE: 'personalised_page',
  COLLECTION: 'collection',
  CHANGE_INSTA_HANDLE: 'change_insta_handle',
  MORE_PAGE: 'more_page',
  SELFIE_TRAY: 'selfie_tray',
  SELFIE_CARD: 'selfie_card',
  ARTIST: 'artist',
  MEDIA_CARD: 'media_card',
  IMAGE_REVIEW: 'image_review',
  FULL_SCREEN_VARIANT: 'full_screen_variant',
  SEARCH: 'search',
  FEED: 'feed',
  ARTIST_DETAILS: 'artist_details',
  BRAND_DETAILS: 'brand_details',
  BRAND_COLLAB_DETAIL: 'brand_collab_detail',
  PRODUCT_DETAIL: 'product_detail',
  POST_DETAIL: 'post_details',
  STORE: 'store',
  SELFIE_REVIEW_PROMPT: 'selfie_review_prompt',
  CAMERA: 'camera',
  REFER_AND_EARN: 'refer_and_earn',
  SALON_LOCATION: 'salon_location',
  MYKIT: 'my_kit',
  VARIANTS: 'variant_modal',
  HOME: 'home',
  TAG_DETAIL: 'tag_detail',
  CART: 'cart',
  ARTIST_PROPOSITION_QUICK_VIEW: 'artist_proposition_quick_view',
  FREE_GIFTS: 'free_items',
  OFFER_DETAIL: 'OfferDetail',
  COMBO_OFFER_DETAIL: 'combo_offer_detail',
  OFFER: 'offer',
  SELFIE_INTRO: 'SelfieIntro',
  NON_PERSONALISED_FEED: 'non_personalised_feed',
  SELFIE_REVIEW: 'selfie_review',
  MY_ORDERS: 'my_orders',
  CONTENT_PAGE: 'content_page',
  ORDER_SUCCESS: 'order_success',
  CHECKOUT: 'checkout',
  FOLLOW_RECOMMENDATION: 'follow_recommendation',
  EMPTY_SEARCH: 'empty_search',
  PROFILE_ICON: 'profile_icon',
  ATTRIBUTE_SELECTOR: 'AttributeSelector',
  PAYOFF_EMPTY_STATE: 'PayoffEmptyState',
  CONTENT_PAGE_CAROUSEL: 'ContentPageCarousel',
  FULL_SCREEN_SELFIE_BANNER: 'fullScreenSelfieBanner',
  ALT_BRANDS: 'altBrands',
  PAYOFF: 'payoff',
  NOTIFICATION_CENTER: 'notification_center',
  WISH_DEALS: 'wish_deals',
  INVITE_CENTRE: 'invite_centre',
  INVITE_CONTACTS: 'invite_contacts',
  WISHLIST_BUILDER: 'wishlist_builder',
  PRODUCT_CARD: 'product_card',
  INVITE_TO_CLAIM_MODAL: 'invite_to_claim_modal',
  PERSONALISED_HORIZONTAL: 'personalised_horizontal',
  INFLUENCER: 'influencer',
  MEDIA_COMPONENT_WITHOUT_CONTROLS: 'MediaComponentWithoutControls',
  ROUTINE_DETAILS: `routine_details`,
  MY_REWARDS: 'my_rewards',
  POINTS_HISTORY: 'points_history',
  RETAIL_STORE: 'retailStore',
  PRE_ROUTINE: 'pre_routine',
  ROUTINES_PAGE: 'routines_page',
  DURING_ROUTINE: 'during_routine',
  POST_ROUTINE: 'post_routine',
  SUBSCRIBE_ROUTINE: 'subscribe_routine',
  TAG: 'Tag',
  LOYALTY_PLANS: 'loyalty_plans',
  NON_MEMBER_LANDING_PAGE: 'foxy_edge_non_member',
  MEMBER_LANDING_PAGE: 'foxy_edge_member',
  DATE_OF_BIRTH: 'date_of_birth',
  ORDER_DETAILS: 'order_details',
  EDGE_SELFIE_CARD: 'edge_selfie_card',
  WHERE_IS_MY_ORDER: 'where_is_my_order',
  RETURN_REPLACE_REASONS: 'return_replace_reasons',
  CONTACT_US_REASONS: 'contact_us_reasons',
  REQUEST_CALLBACK_PAGE: 'request_callback_page',
  SELECT_CALLBACK_SLOT_TIME: 'select_callback_slot_time',
  CONFIRM_REQUEST_PAGE: 'confirm_request_page',
  CANCEL_ORDER_REASON_PAGE: 'cancel_order_reason_page',
  ACTIVE_CALLBACK_CONFIRM_REQUEST_PAGE: 'active_callback_confirm_request_page',
  FAQ_ANSWER_SECTION: 'faq_answer_section',
  LIST_TAB: 'LIST_TAB',
  PAYMENT: 'payment',
  PRODUCT_SUMMARY: 'product_summary',
  POPUP_ALERT: 'PopUpAlert',
  CONTACT_US_MODAL: 'ContactUsModal',
  CATEGORY_DETAILS: 'CategoryDetail',
  loyalty_plans: 'LoyaltyPlans',
  product_offers_rail: 'product_offers_rail',
  FULL_SCREEN_VARIANT: 'FullScreenVariant',
  CATEGORY: 'Category',
  ORDER_HISTORY: 'OrdersHistory',
  TODAY_DEALS: 'TodayDeals',
  ALERT_MODAL: 'AlertModal',
  VARIANT_MODAL: 'VariantModal',
  BECOME_MEMBER_MODAL: 'BecomeMemberModal',
  UPGRADE_EDGE_MODAL: 'UpgradeEdgeModal',
  VERTICAL_TABBED: 'verticalTabs',
  CATEGORY_BROWSING: 'categoryBrowsing',
  MORE_ITEMS: 'MoreItems',
  WISHLIST: 'Wishlist',
  PRODUCT_PAGE: 'Product',
  CLUBBABLE_MODAL: 'ClubbableModal',
  UNFULFILLED_OFFER_MODAL: 'UnfulfilledOfferModal',
  WALLET_PAGE: 'wallet_page',
  PRODUCT_WISHLISTING: 'product_wishlisting',
  NOTIFY_ME: 'notify_me',
  ORDER_COMPLETION: 'order_completion',
  WISHLIST_PAGE: 'wishlist_page',
  PRE_ROUTINE: 'pre_routine',
  ACTIVE_ROUTINE: 'active_routine',
  HANDLE_SELECTION: 'HandleSelection',
  ABOUT_YOU: 'AboutYou',
  SAVE_PROGRESS: 'SaveProgress',
  CONSULTATION_DETAILS: 'ConsultationDetails',
  SOCIAL_PROFILE: 'SocialProfile',
  ONBOARD_CUSTOMER: 'OnboardCustomers',
};
